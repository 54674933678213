<template>
  <div id="app" :style="{ fontFamily: selectedFont, backgroundColor: selectedBackgroundColor }">
    <HamburgerMenu />

    <div class="app-settings">
      <!-- Font Selection -->
      <select v-model="selectedFont" @change="saveFontPreference">
        <option v-for="font in availableFonts" :key="font" :value="font">{{ font }}</option>
      </select>

      <!-- Background Color Picker -->
      <input type="color" v-model="selectedBackgroundColor" @change="saveColorPreference" />
    </div>

    <!-- Router View -->
    <router-view
      :selectedFont="selectedFont"
      :selectedBackgroundColor="selectedBackgroundColor">
    </router-view>
  </div>
</template>


<script>
import HamburgerMenu from './components/HamburgerMenu.vue';
import chroma from 'chroma-js';

//import UserCalendar from './components/UserCalendar.vue'; // Ensure the path is correct

export default {
  name: 'App',
  components: {
    HamburgerMenu,
    //UserCalendar

  },
  data() {
    return {
      selectedFont: 'Arial',  // Set your default font
      selectedBackgroundColor: '#95F2FE', // default background color
      availableFonts: ['Arial', 'Verdana', 'Helvetica', 'Times New Roman', '...'] // Add more fonts as needed
    };
  },
  computed: {
    moreSaturatedBackgroundColor() {
      return chroma(this.selectedBackgroundColor).saturate(1).hex();
    },
    lessSaturatedBackgroundColor() {
      return chroma(this.selectedBackgroundColor).desaturate(1).hex();
    }
  },
  mounted() {
    this.adjustContentMargin();
    //this.selectedBackgroundColor
    window.addEventListener('resize', this.adjustContentMargin);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustContentMargin);
  },
  methods: {
    saveFontPreference() {
      // Save the selected font to localStorage
      localStorage.setItem('selectedFont', this.selectedFont);
    },
    updateSelectedFont(newFont) {
      this.selectedFont = newFont;
    },
    saveColorPreference() {
      // Save background color preference to local storage
      localStorage.setItem('selectedBackgroundColor', this.selectedBackgroundColor);
    },
    adjustContentMargin() {
    const header = this.$el.querySelector('.header-container');
    const content = this.$el.querySelector('.content-container');
    if (header && content) {
      const headerHeight = header.offsetHeight;
      content.style.marginTop = `${headerHeight}px`;
    }
  }
  },
  created() {
    // Check for a saved font preference in localStorage
    const savedFont = localStorage.getItem('selectedFont');
    if (savedFont) {
      this.selectedFont = savedFont;
    }
  }

}
</script>


<style>
body {
  font-family: 'DefaultFont', sans-serif; /* Replace 'DefaultFont' with your actual default font */
}

.content-container {
  padding-top: 10vh; /* Space for the header, adjust based on header's max-height */
  /* Other styling for the content container */
}
.header-container {
    /* Your header styles */
    position: fixed; /* or 'absolute' depending on your design */
    top: 0;
    width: 100%;
    z-index: 10; /* Ensure header stays above other content */
  }

#app {
  margin-top: 40px; /* No additional top margin needed */
}

/* Example media query for larger screens - for other stylistic changes */
@media (min-width: 801px) {
  /* Add any specific styles for larger screens here */
}

</style>
