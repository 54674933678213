<template>
  <div>
    <!-- Check if user data is still loading -->
    <div v-if="!isUserDataLoaded">
      <p>Loading user data...</p>
    </div>
    <!-- User data loaded -->
    <div v-else>
      <!-- Check if editor content is still loading -->
      <div v-if="isEditorLoading">
        <p>Loading content...</p>
      </div>
      <!-- Editor content loaded -->
      <div v-else>
        <!-- Check user authentication and role -->
        <div v-if="currentUser && currentUser.role">
          <!-- Display CKEditor for admin role -->
          <ckeditor v-if="currentUser.role === 'admin'" v-model="editorData" :editor="editor" :config="editorConfig" @input="triggerAutoSave"></ckeditor>
          <!-- Display readonly content for user role -->
          <div v-if="currentUser.role === 'user'" v-html="editorData"></div>
          <!-- Update and Export buttons for admin role -->
          <button v-if="currentUser.role === 'admin'" @click="updateManual">Update User Manual</button>
          <button v-if="currentUser.role === 'admin'" @click="exportToPDF">Export to PDF</button>
        </div>
        <!-- User not logged in or role not found -->
        <div v-else>
          <p>Please log in to view the manual.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '../assets/ckeditor/ckeditor.js';
import { db } from '@/firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import html2pdf from 'html2pdf.js';

export default {
  components: {
    ckeditor: CKEditor.component
  },
  setup() {
    const editorData = ref('<p>Start editing...</p>');
    const currentUser = ref(null);
    const isUserDataLoaded = ref(false);
    const isEditorLoading = ref(true);
    const editor = ClassicEditor;
    const editorConfig = ref({});

    const isUserAuthorized = computed(() => {
      return currentUser.value && currentUser.value.role === 'admin';
    });
    const autoSaveTimeout = ref(null); // Reactive reference for the timeout

    const triggerAutoSave = () => {
      clearTimeout(autoSaveTimeout.value);
      autoSaveTimeout.value = setTimeout(() => {
        updateManual(); // Ensure updateManual is accessible here
      }, 5000); // Auto-save after 5 seconds of inactivity
    };
    const fetchManualContent = async () => {
      isEditorLoading.value = true;
      try {
        const docRef = doc(db, "manuals", "sfp_user_manual");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          editorData.value = docSnap.data().content;
        }
      } catch (error) {
        console.error("Error fetching manual content:", error);
      }
      isEditorLoading.value = false;
    };

    onMounted(fetchManualContent);

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        currentUser.value = { ...user, role: userDoc.exists() ? userDoc.data().role : null };
      } else {
        currentUser.value = null;
      }
      isUserDataLoaded.value = true;
    });

    const updateManual = async () => {
      if (!isUserAuthorized.value) {
        alert('You are not authorized to edit the manual.');
        return;
      }
      isEditorLoading.value = true;
      try {
        const docRef = doc(db, "manuals", "sfp_user_manual");
        await setDoc(docRef, { content: editorData.value });
        alert("User Manual updated successfully!");
      } catch (error) {
        console.error("Error updating manual:", error);
        alert("Error updating User Manual!");
      }
      isEditorLoading.value = false;
    };

    const exportToPDF = () => {
      const element = document.createElement('div');
      element.innerHTML = editorData.value;
      html2pdf()
        .from(element)
        .save('sfp_user_manual.pdf');
    };

    return {
      triggerAutoSave,
      editorData,
      editor,
      editorConfig,
      currentUser,
      updateManual,
      exportToPDF,
      isUserDataLoaded,
      isEditorLoading,
      isUserAuthorized
    };
  }
};
</script>

<style>
/* Add styles for your editor if needed */
</style>
