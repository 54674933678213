<template>
  <div v-if="show" class="custom-toast">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'CustomToast',
  data() {
    return {
      show: false,
      message: ''
    };
  },
  methods: {
    showToast(newMessage) {
      this.message = newMessage;
      this.show = true;
      setTimeout(() => this.show = false, 3000); // Hide toast after 3 seconds
    }
  }
};
</script>

<style>
.custom-toast {
  position: fixed;
  bottom: 200px;
  left: 200px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 10000;
}
</style>
