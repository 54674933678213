import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import VueMasonry from 'vue-masonry-css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
// Import global CSS
import './assets/global.css';


const toastOptions = {
  position: 'top-right', // Position of the toast on the screen
  timeout: 5000,        // Time in milliseconds for the toast to be auto-dismissed
  closeOnClick: true,   // Whether to show the close button on toast
  pauseOnHover: true,   // Whether to pause the timer when the mouse hovers over the toast
  showProgressBar: true,// Whether to display a progress bar under the toast
  maxToasts: 5,         // Maximum number of toasts that can be displayed at once
  newestOnTop: true,    // Whether new toasts should appear at the top or bottom of the stack
  transition: 'slide',  // Transition of the toast entering and leaving the screen
  draggable: true,      // Whether toasts should be draggable
  draggablePercent: 0.6,// The percentage of the toast's width it takes for the toast to be dismissed while dragging
  focusable: false,     // Whether the toast container should be focusable (for accessibility)
  parseHtml: false      // Whether to render HTML content inside the toast
};
// Create a new store instance
const store = createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    // ... other mutations
  },
  // ... other store properties
});
// Create the app instance
const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueMasonry);

app.use(Toast, toastOptions); // Use the defined toast options
app.mount('#app');