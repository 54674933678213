<template>
  <div v-if="isUserAuthorized">
    <!-- Check if editorData is available -->
    <div v-if="editorData">
      <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
      <button @click="updateManual">Update Manual</button>
      <button @click="exportToPDF">Export to PDF</button>
    </div>
    <!-- Show loading message if editorData is not available -->
    <div v-else>
      <p>Loading editor...</p>
    </div>
  </div>
  <!-- Show not authorized message if the user is not authorized -->
  <div v-else>
    <p>You are not authorized to edit the manual.</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '../assets/ckeditor/ckeditor.js';
import { db } from '@/firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import html2pdf from 'html2pdf.js';


export default {
  components: {
    ckeditor: CKEditor.component
  },
  setup() {
    const editorData = ref('<p>Start editing here come on...</p>');
    const currentUser = ref(null);
    const editor = ClassicEditor;
    const editorConfig = ref({});

    const isUserAuthorized = computed(() => {
      const allowedEmails = ['cmantra888@yahoo.com'];
      return currentUser.value && allowedEmails.includes(currentUser.value.email);
    });

    const fetchManualContent = async () => {
      const docRef = doc(db, "manuals", "sfp_docs");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        editorData.value = docSnap.data().content;
      } else {
        console.log("No manual content found!");
      }
    };

    onMounted(() => {
      fetchManualContent();
    });

    onAuthStateChanged(auth, (user) => {
      currentUser.value = user;
    });

    const updateManual = async () => {
      if (!isUserAuthorized.value) {
        alert('You are not authorized to edit the manual.');
        return;
      }

      try {
        const docRef = doc(db, "manuals", "sfp_docs");
        await setDoc(docRef, { content: editorData.value });
        alert("Manual updated successfully!");
      } catch (error) {
        console.error("Error updating manual:", error);
      }
    };
    const exportToPDF = () => {
      const element = document.createElement('div');
      element.innerHTML = editorData.value;
      html2pdf()
        .from(element)
        .save('manual.pdf');
    };

    return { editorData, editor, editorConfig, isUserAuthorized, updateManual, exportToPDF };
  }
};
</script>


<style>
/* Add styles for your editor if needed */
</style>
