<template>
  <div class="csv-manager">
    <!-- Header Section -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image">
    </div>
    <h2>CSV Import/Export</h2>

    <!-- Select CSV Button -->
    <button @click="triggerFileUpload" class="select-csv-button">Select CSV to Import</button>
    <input type="file" @change="importCSV" ref="fileInput" accept=".csv" style="display: none;" />

    <!-- Export CSV Button -->
    <button @click="exportCSV" class="export-csv-button">Export Firebase Cards to CSV</button>

    <p v-if="importMessage">{{ importMessage }}</p>

    <!-- Instructional Message in a Box -->
    <div class="instruction-box">
      <p>
        The CSV export will go to:
      <br /><strong>/storage/emulated/0/CSV_DB_Backup/</strong>
      </p>
      <p>
        The file will be called...
        <strong>database_backup.csv</strong>
      </p>
      <p>
        This can be restored at any time by selecting the CSV to import and locating the file. You can also import additional Cards with the same .csv format just by locating the file.
      </p>
      <p>
        The required CSV format is:
        <br /><strong>Category, Subcategory, Item, Description, Details, Difficulty</strong>
      </p>
      <p>
        If Difficulty is not included, it will default to <strong>"Easy"</strong>. Difficulty should be limited to <strong>"Easy"</strong>, <strong>"Medium"</strong>, <strong>"Hard"</strong>, and I am working on <strong>"P1"</strong>, <strong>"P2"</strong>, and <strong>"P3"</strong>.
      </p>
      <p>
        The <strong>P</strong> levels will be implemented as a basic to-do list.
      </p>
      <p>
        Images can also be selected by pressing the image in the Edit Card screen.
      </p>
    </div>
  </div>
</template>

<script>
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig';  // Adjust based on your Firebase config
import { getAuth } from "firebase/auth";  // Firebase authentication

export default {
  data() {
    return {
      importMessage: '',
      words: [], // For storing words from the CSV
      userEmail: '', // To store the authenticated user's email
    };
  },
  async mounted() {
    // Retrieve the authenticated user's email when the component is mounted
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.userEmail = user.email;
    } else {
      this.importMessage = "User is not authenticated. Please sign in to import cards.";
    }
  },
  methods: {
    // Trigger file input click
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },

    // Import CSV file
    async importCSV(event) {
      const file = event.target.files[0];
      if (!file || !file.name.endsWith('.csv')) {
        this.importMessage = "Please select a valid CSV file.";
        return;
      }

      // Parse CSV file
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvContent = e.target.result;
        this.parseCSV(csvContent);
      };
      reader.readAsText(file);
    },

    // Parse the CSV content and save to Firestore
    async parseCSV(content) {
      const lines = content.split('\n');
      const parsedWords = [];

      lines.forEach((line) => {
        const columns = line.split(',');

        if (columns.length >= 6) {
          const word = {
            category: this.capitalizeWords(columns[0].trim()),
            subcategory: this.capitalizeWords(columns[1].trim()),
            item: this.capitalizeWords(columns[2].trim()),
            description: columns[3].trim(),
            details: columns[4].trim(),
            difficulty: columns[5].trim() || 'Easy',
            email: this.userEmail, // Add the authenticated user's email
          };

          // Check if the word is duplicate
          if (!this.isDuplicateWord(parsedWords, word)) {
            parsedWords.push(word);
          }
        }
      });

      this.words = parsedWords;
      this.importMessage = `${this.words.length} words imported successfully.`;

      // Save to Firestore
      await this.saveToFirestore();
    },

    // Check for duplicate words
    isDuplicateWord(wordList, word) {
      return wordList.some(
          (existingWord) =>
              existingWord.item === word.item &&
              existingWord.description === word.description &&
              existingWord.category === word.category &&
              existingWord.subcategory === word.subcategory &&
              existingWord.details === word.details &&
              existingWord.difficulty === word.difficulty
      );
    },

    // Capitalize words
    capitalizeWords(text) {
      return text
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    },

    // Save parsed words to Firestore collection
    async saveToFirestore() {
      const cardsCollection = collection(db, 'cards');
      try {
        for (const word of this.words) {
          await addDoc(cardsCollection, {
            Category: word.category,
            Subcategory: word.subcategory,
            Item: word.item,
            Description: word.description,
            Details: word.details,
            Difficulty: word.difficulty,
            Email: word.email, // Save the associated email with each card
          });
        }
        this.importMessage += ' Cards have been successfully saved to Firestore.';
      } catch (error) {
        this.importMessage = `Error saving cards: ${error.message}`;
      }
    },

    // Export words to CSV from Firebase
    async exportCSV() {
      try {
        const cardsCollection = collection(db, 'cards');
        const snapshot = await getDocs(cardsCollection);

        // Start building the CSV content with UTF-8 encoding and handling commas
        let csvContent = '\uFEFFCategory,Subcategory,Item,Description,Details,Difficulty,Image,Audio\n';

        snapshot.forEach((doc) => {
          const card = doc.data();

          // Function to safely format each field to handle commas, newlines, and special characters
          const formatCSVField = (field) => {
            if (!field) return '';
            field = field.replace(/"/g, '""'); // Escape quotes
            return `"${field}"`; // Wrap the field in quotes to handle commas and newlines
          };

          csvContent += `${formatCSVField(card.Category)},${formatCSVField(card.Subcategory)},${formatCSVField(card.Item)},`;
          csvContent += `${formatCSVField(card.Description)},${formatCSVField(card.Details)},${formatCSVField(card.Difficulty || 'Easy')},`;
          csvContent += `${formatCSVField(card.Image)},${formatCSVField(card.audio)}\n`;
        });

        // Convert CSV content to a Blob for UTF-8 encoding
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

        // Create a download link for the CSV file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'firebase_cards_export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.importMessage = 'CSV export successful!';
      } catch (error) {
        console.error("Error exporting CSV:", error);
        this.importMessage = 'Error exporting CSV.';
      }
    },
  },

};
</script>

<style scoped>

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.csv-manager {
  text-align: center;
}

.select-csv-button,
.export-csv-button {
  padding: 10px;
  margin: 10px;
  background-color: darkblue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.select-csv-button:hover,
.export-csv-button:hover {
  background-color: darkgreen;
}

.instructions {
  margin-top: 20px;
  font-size: 14px;
  text-align: left;
  padding: 0 20px;
}

.instructions strong {
  font-weight: bold;
}
.instruction-box {
  width: 90%;
  max-width: 350px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 16px;
  color: #333;
}

.instruction-box p {
  margin: 10px 0;
}
</style>
