<template>
  <div class="hello">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <!-- <h1>{{ msg }}</h1>-->
      <p class="no-margin">Choose your weapons...</p>
      <!-- User Authentication and Edit Manual link -->
      <UserAuth v-if="!currentUser" />
      <!-- Show Edit Manual link for any authenticated user
      <router-link v-if="currentUser" to="/edit-manual">Edit Manual</router-link>-->
      <!-- Conditionally show "Show" or "Edit" User Manual link based on user role -->
      <router-link
      v-if="currentUser && userRole === 'admin'"
      to="/edit-user-manual"
      class="manual-edit-btn">
      Edit User Manual
    </router-link>
    <router-link
      v-else-if="currentUser && userRole === 'user'"
      to="/edit-user-manual"
      class="manual-edit-btn">
      Show User Manual
    </router-link>
    <!-- Show Edit Admin Manual link only for admin role -->
    <router-link
      v-if="userRole === 'admin'"
      to="/edit-admin-manual"
      class="manual-edit-btn">
      Edit Admin Manual
    </router-link>
   <router-link
      v-if="currentUser"
      to="/user-notes"
      class="manual-edit-btn">
      Create Notes
    </router-link>
    <router-link
      v-if="currentUser"
      to="/user-calendar"
      class="manual-edit-btn">
      User Calendar
    </router-link>
    <button
      v-if="currentUser"
      @click="signOut"
      class="sign-out-btn">
      Sign Out
    </button>

      <!-- Debugging information -->
      <div v-if="currentUser">Current User: {{ currentUser.email }}</div>
      <div v-if="userRole === 'admin'">User is authorized</div>
      <div v-if="currentUser && userRole !== 'admin'">User is not authorized for admin tasks</div>

      <!-- Firestore Data Component -->
      <FirestoreData v-if="currentUser" />
    </div>
  </div>
</template>


<script>
import FirestoreData from './FirestoreData.vue';
import UserAuth from './UserAuth.vue';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import { db } from '@/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: 'HelloWorld',
  components: {
    UserAuth,
    FirestoreData
  },
  data() {
    return {
      currentUser: null,
      userRole: null,
      loading: true,
      msg: String,
    };
  },
  created() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        this.currentUser = user;
        this.userRole = userDoc.exists() ? userDoc.data().role : null;
      } else {
        this.currentUser = null;
        this.userRole = null;
      }
      this.loading = false;
    });
  },
  methods: {
    signOut() {
      signOut(auth).then(() => {
        console.log('Signed out successfully');
        this.currentUser = null;
        this.userRole = null;
      }).catch((error) => {
        console.error('Sign out error:', error);
      });
    }
  }
};
</script>

<style>
.hello {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 50px); /* Adjust based on your header height */
  overflow-y: auto; /* Enable scrolling */
  margin-top: 00px; /* Push down to start below the header */
  padding-bottom: 0px; /* Add some space at the bottom */
}

.no-margin {
  margin: 3px 0;
  margin-left: 10px;
  /* Other styles */
}

/* If the header height is different for smaller screens, adjust accordingly */
@media (max-width: 768px) {
  .hello {

    flex-direction: column;
    max-height: calc(100vh - 180px); /* Adjust for smaller screens */
    margin-top: 0px; /* Adjust top margin for smaller screens */
  }
.manual-edit-btn, .sign-out-btn {
  font-size: 12px !important;
  padding: 5px 8px !important;
}


}
</style>
