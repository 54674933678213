<template>
  <div v-if="isUserDataLoaded">
    <div v-if="isUserAuthorized">
      <!-- Check if editor is loading -->
      <div v-if="isEditorLoading">
        <p>Loading editor...</p>
      </div>
      <!-- Display editor if it's not loading -->
      <div v-else>
        <ckeditor v-model="editorData" :editor="editor" :config="editorConfig" @input="triggerAutoSave"></ckeditor>
        <button @click="updateManual">Update Admin Manual</button>
        <button @click="exportToPDF">Export to PDF</button>
      </div>
    </div>
    <!-- Show not authorized message if the user is not authorized -->
    <div v-else>
      <p>You are not authorized to edit the manual.</p>
    </div>
  </div>
  <div v-else>
    <p>Loading user data...</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '../assets/ckeditor/ckeditor.js';
import { db } from '@/firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import html2pdf from 'html2pdf.js';

export default {
  components: {
    ckeditor: CKEditor.component
  },
  setup() {
    const editorData = ref('<p>Start editing...</p>');
    const currentUser = ref(null);
    const isUserDataLoaded = ref(false);
    const isEditorLoading = ref(true); // Track loading state of the editor
    const editor = ClassicEditor;
    const editorConfig = ref({});

    const isUserAuthorized = computed(() => {
      return currentUser.value && currentUser.value.role === 'admin';
    });

    const autoSaveManual = async () => {
      if (!isUserAuthorized.value) return; // No operation if user not authorized

      try {
        const docRef = doc(db, "manuals", "sfp_admin_manual");
        await setDoc(docRef, { content: editorData.value });
        // No alert for auto-save
      } catch (error) {
        console.error("Error auto-saving manual:", error);
      }
    };

    let autoSaveTimeout = null;
    // Trigger for auto-save
    const triggerAutoSave = () => {
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => {
        autoSaveManual();
      }, 5000); // Auto-save after 5 seconds of inactivity
    };
    const fetchManualContent = async () => {
      isEditorLoading.value = true; // Start loading
        try {
          const docRef = doc(db, "manuals", "sfp_admin_manual");
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            editorData.value = docSnap.data().content;
          } else {
            console.log("No manual content found!");
          }
        } catch (error) {
          console.error("Error fetching manual content:", error);
        }
        isEditorLoading.value = false; // End loading regardless of success or failure
      };

    onMounted(() => {
      fetchManualContent();
    });

   onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch the user's role from Firestore
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        currentUser.value = { ...user, role: userDoc.exists() ? userDoc.data().role : null };
      } else {
        currentUser.value = null;
      }
      isUserDataLoaded.value = true;
    });
    const updateManual = async () => {
      console.log("Updating manual with content:", editorData.value);
      if (!isUserAuthorized.value) {
        alert('You are not authorized to edit the manual.');
        return;
      }

      isEditorLoading.value = true; // Start loading
      try {
        const docRef = doc(db, "manuals", "sfp_admin_manual");
        await setDoc(docRef, { content: editorData.value });
        alert("Admin Manual updated successfully!");
      } catch (error) {
        console.error("Error updating manual:", error);
      }
      isEditorLoading.value = false; // End loading
    };

    const exportToPDF = () => {
      const element = document.createElement('div');
      element.innerHTML = editorData.value;
      html2pdf()
        .from(element)
        .save('sfp_admin_manual.pdf');
    };

    return { triggerAutoSave, editorData, editor, editorConfig, isUserAuthorized, updateManual, exportToPDF, isUserDataLoaded, isEditorLoading };
  }
};
</script>


<style>
/* Add styles for your editor if needed */
</style>
