<template>
  <div class="chatbot">
    <!-- Header with Logo -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image" />
    </div>

    <!-- Translation Controls -->
    <div v-if="mode === 'translation'" class="translation-controls">
      <label>From:</label>
      <select v-model="fromLanguage">
        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
      </select>

      <label>To:</label>
      <select v-model="toLanguage">
        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
      </select>
    </div>

    <!-- Text Input Section -->
    <div class="input-section">
      <textarea v-model="userInput" :placeholder="inputPlaceholder" class="responsive-input"></textarea>
    </div>

    <!-- Mode, Send, and Clear Buttons -->
    <div class="button-group">
      <button @click="toggleMode" class="control-button dark-blue">{{ modeLabel }}</button>
      <button @click="sendMessage" class="control-button dark-green">Send</button>
      <button @click="clearInput" class="control-button dark-red">Clear</button>
      <button @click="saveToNote(lastChatbotResponse.value)" class="control-button purple">Save Nt</button>
    </div>

    <!-- Chat Output -->
    <div class="chat-output">
      <p v-for="message in chatHistory" :key="message.id">{{ message.text }}</p>
    </div>

    <!-- Voice Selection Dropdown -->
    <div class="voice-dropdown">
      <label for="voiceSelect">Voice:</label>
      <select v-model="selectedVoice" id="voiceSelect">
        <option v-for="voice in voices" :key="voice.name" :value="voice">{{ voice.name }}</option>
      </select>
    </div>

    <!-- Audio Control Buttons -->
    <div class="audio-control">
      <button @click="toggleListening" :class="listening ? 'listening-active' : 'listening-inactive'">
        <span v-if="!listening">🎤</span> <!-- Microphone Icon -->
        <span v-else>🛑</span> <!-- Stop Icon -->
      </button>

      <button @click="toggleMute" :class="isMuted ? 'mute-active' : 'mute-inactive'">
        <span v-if="isMuted">🔇</span>
        <span v-else>🔊</span>
      </button>

      <button @click="playLastMessage" :class="{'playback-muted': isMuted, 'playback-ready': !isMuted && !playbackActive, 'playback-active': playbackActive}">
        🔊
      </button>
    </div>
  </div>
</template>


<script>
/*import { ref, onMounted } from 'vue';
import { db, auth, storage } from '../firebaseConfig';
import { addDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
*/

import { ref, onMounted, computed} from 'vue';
import { db, auth } from '../firebaseConfig';
import { addDoc, collection, where, updateDoc, query, getDocs } from 'firebase/firestore';
//import { addDoc, collection, getDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
//import { getDownloadURL } from 'firebase/storage';

export default {
  name: 'ChatbotComponent',
  setup() {
    const mode = ref('chat'); // Modes: chat, translation, fact

    // Data properties
    const userInput = ref('');
    const chatHistory = ref([]);
    const lastChatbotResponse = ref('');
    const listening = ref(false);
    const isMuted = ref(true);
    const playbackActive = ref(false);
    let recognition;
    const audioPlayer = new SpeechSynthesisUtterance();

    const fromLanguage = ref('en'); // default to English
    const toLanguage = ref('frzh-PY');   // default to French
    const languages = [
      {name: 'English', code: 'en'},
      {name: 'French', code: 'fr'},
      {name: 'Spanish', code: 'es'},
      {name: 'Simplified Chinese', code: 'zh-CN'},
      {name: 'Pinyin', code: 'zh-PY'},
      {name: 'German', code: 'de'},
      {name: 'Danish', code: 'da'},
      {name: 'Thai', code: 'th'}
    ];

    const voices = ref([]);
    const selectedVoice = ref(null);

    // Fetch the available voices
    const loadVoices = () => {
      voices.value = window.speechSynthesis.getVoices();
      const lastChosenVoice = localStorage.getItem('selectedVoice');
      if (lastChosenVoice) {
        selectedVoice.value = voices.value.find(voice => voice.name === lastChosenVoice) || voices.value[0];
      } else {
        selectedVoice.value = voices.value[0];
      }
    };

    // Play the chatbot's response if unmuted
    const playResponse = (text) => {
      if (isMuted.value || !text) return;

      const synth = window.speechSynthesis;
      audioPlayer.text = text;
      audioPlayer.lang = 'en-US'; // Set the language as needed
      synth.speak(audioPlayer);
    };
    // Method to play the last chat message
    const playLastMessage = () => {
      if (!lastChatbotResponse.value) return;

      if (!isMuted.value) {
        playbackActive.value = true;
        speakResponse(lastChatbotResponse.value);
      } else {
        console.log("Playback is muted.");
      }
    };

    onMounted(() => {
      if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      }
      // Retry voice loading in case voices don’t populate on the first attempt
      setTimeout(loadVoices, 500); // Delay to ensure voices load properly
    });

    const toggleListening = () => {
      if (!listening.value) {
        startListening();
      } else {
        stopListening();
      }
    };

    const startListening = () => {
      if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
        alert('Speech Recognition is not supported in this browser.');
        return;
      }

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition = new SpeechRecognition();
      recognition.lang = 'en-US'; // Set this as needed
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onstart = () => {
        listening.value = true;
      };

      recognition.onresult = (event) => {
        const speechResult = event.results[0][0].transcript;
        userInput.value = speechResult;
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        stopListening();
      };

      recognition.onend = () => {
        listening.value = false; // Reset button state when listening ends
      };

      recognition.start();
    };

    const stopListening = () => {
      if (recognition) {
        recognition.stop();
      }
      listening.value = false; // Reset the button to green state
    };
    // Computed properties
    const inputPlaceholder = computed(() => {
      if (mode.value === 'translation') return 'Enter text to translate';
      if (mode.value === 'fact') return 'Enter fact text';
      return 'Enter chat message';
    });

    const modeLabel = computed(() => {
      if (mode.value === 'chat') return 'Chat';
      if (mode.value === 'translation') return 'Translation';
      return 'Fact';
    });

    // Toggle between chat and translation mode
    const toggleMode = () => {
      if (mode.value === 'chat') {
        mode.value = 'translation';
      } else if (mode.value === 'translation') {
        mode.value = 'fact';
      } else {
        mode.value = 'chat';
      }
    };

    // Firebase - Save translation
    const saveTranslation = async () => {
      try {
        const note = {
          userId: auth.currentUser.uid,
          content: userInput.value,
          response: lastChatbotResponse.value,
          timestamp: new Date(),
        };
        await addDoc(collection(db, 'translations'), note);
        alert('Translation saved!');
      } catch (error) {
        console.error('Error saving translation:', error);
      }
    };

    // Clear input field
    const clearInput = () => {
      userInput.value = '';
      lastChatbotResponse.value = '';
    };

    // Handle sending message
    const sendMessage = async () => {

      if (!userInput.value) return;

      const requestBodyJson = {
        user_input: userInput.value,
        mode: mode.value,
        from_lang: fromLanguage.value, // language code
        to_lang: toLanguage.value       // language code
      };

      console.log("User Input:", userInput.value);
      console.log("Sent request:", JSON.stringify(requestBodyJson));

      try {
        const response = await fetch("https://www.smartflashprime.au/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyJson),
        });

        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }

        const data = await response.json();
        const chatbotResponse = data.response;

        console.log("Received response:", chatbotResponse);

        // Update chat output in the UI
        chatHistory.value.unshift({text: chatbotResponse, id: Date.now()});
        lastChatbotResponse.value = chatbotResponse;

        // Adjust text size and speak the response if not muted
        if (!isMuted.value) {
          speakResponse(chatbotResponse);
        }
      } catch (error) {
        console.error("Chat request failed:", error.message);
        alert(`Chat request failed: ${error.message}`);
      }
    };

    // Speech synthesis (Text-to-Speech) with dynamic mute handling
    // Speech synthesis (Text-to-Speech) with dynamic mute handling and logs
     // Existing function with voice selection added
    const speakResponse = (text) => {
      console.log("Starting speakResponse with text:", text);

      const synth = window.speechSynthesis;
      const utterThis = new SpeechSynthesisUtterance(text);
      utterThis.lang = fromLanguage.value;

      // Apply the selected voice and save it as last chosen
      if (selectedVoice.value) {
        utterThis.voice = selectedVoice.value;
        localStorage.setItem('selectedVoice', selectedVoice.value.name);
      }

      let checkMute;

      utterThis.onstart = () => {
        console.log("Speech started.");
        checkMute = setInterval(() => {
          if (isMuted.value && synth.speaking) {
            console.log("Muted while speaking, stopping speech.");
            synth.cancel();
            clearInterval(checkMute);
          }
        }, 100);
      };

      utterThis.onend = () => {
        console.log("Speech ended.");
        clearInterval(checkMute);
      };

      utterThis.onerror = (event) => {
        console.error("Speech synthesis error:", event.error);
      };

      console.log("Mute status:", isMuted.value);
      synth.speak(utterThis);
    };

    // Toggle mute and handle immediate audio stop if muted, with logs
    const toggleMute = () => {
      isMuted.value = !isMuted.value;
      console.log("Mute toggled. New mute status:", isMuted.value);

      const synth = window.speechSynthesis;
      if (isMuted.value && synth.speaking) {
        console.log("Mute activated during speech, stopping speech.");
        synth.cancel(); // Immediately stop any ongoing speech when mute is activated
      }
    };

    // Save the received message to the user's note
    const saveToNote = async () => {
      try {
        const userId = auth.currentUser.uid; // Ensure you're getting the authenticated user's ID
        const notesRef = collection(db, 'notes');
        const q = query(notesRef, where('name', '==', 'Chat'), where('userId', '==', userId));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const chatNoteDoc = querySnapshot.docs[0];
          const currentContent = chatNoteDoc.data().content || '';
          // Prepend the response with timestamp and mode, using the chatResponse text
          const updatedContent = `
            <p><b>${new Date().toLocaleString()}</b> - ${mode.value}</p>
            <p><b>Sent:</b> ${userInput.value}</p>
            <p><b>Response:</b> ${lastChatbotResponse.value}</p>
            ${currentContent}`;

          await updateDoc(chatNoteDoc.ref, { content: updatedContent });
          alert("Chat saved successfully!");

          //console.log("Response saved to 'Chat' note.");
        } else {
          console.error("No 'Chat' note found for this user.");
          alert("Failed to save the chat - No 'Chat' note found for this user.");
        }
      } catch (error) {
        console.error("Error saving response to 'Chat' note:", error);
        alert("Failed to save the chat.");

      }
    };

    return {
      userInput,
      listening,
      toggleListening,
      chatHistory,
      mode,
      inputPlaceholder,
      modeLabel,
      lastChatbotResponse,
      playLastMessage,
      playbackActive,
      saveToNote,
      fromLanguage,
      toLanguage,
      languages,
      isMuted,
      voices,
      selectedVoice,
      speakResponse,
      toggleMode,
      sendMessage,
      clearInput,
      toggleMute,
      startListening,
      stopListening,
      saveTranslation,
      playResponse

    };
  },
};
</script>

<style scoped>
.chatbot {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  align-items: center; /* Center elements horizontally */
  font-family: "Arial", sans-serif; /* Set the font for the whole app */
}

/* Header Styling */
.header-container {
  display: flex;
  max-height: 60px; /* Limit overall header height */
  justify-content: center; /* Center the logo horizontally */
  padding: 5px; /* Reduce padding for a tighter fit */
}

/* Header Image Styling */
.header-container img {
  max-width: 100%; /* Allow it to fill available width */
  max-height: 80px; /* Limit image height */
  height: auto;
  object-fit: contain; /* Maintain aspect ratio */
  transition: max-width 0.3s ease, max-height 0.3s ease; /* Smooth scaling transition */
}

/* Input Section */
.input-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Full width */
  max-width: 800px;
  margin-bottom: 20px;
}

/* Style for the Input Box */
.responsive-input {
  padding: 10px;
  margin-right: 10px;
  width: 100%;
  max-width: 800px;
  min-width: 400px;
  height: 100px;
  resize: none;
  overflow-wrap: break-word;
  box-sizing: border-box;
  font-family: inherit; /* Ensure it inherits the app-wide font */
}

/* Chat Output (Response Box) */
.chat-output {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px 0;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  max-width: 750px;
  min-width: 350px;
  background-color: white; /* Set background to white */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: inherit; /* Consistent font */
}

/* Style for Control Buttons */
.control-button {
  margin: 2px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  color: white; /* Ensure button text color is white */
  width: 100px;
}

.voice-dropdown select {
  padding: 5px;
  font-size: 16px;
  margin-top: 10px;
}
.button-group {
  display: flex;
  justify-content: center;
  gap: 0px;
}

/* Additional Styling for Colors and Button States */
.control-button.dark-green { background-color: darkgreen; color: white; }
.control-button.red { background-color: red; color: white; }
.control-button.purple { background-color: purple; color: white; }
.control-button.dark-blue { background-color: darkblue; color: white; }
.control-button.dark-red { background-color: darkred; color: white; }

.audio-controls button {
  margin-right: 10px;
}

.translation-controls select {
  margin-right: 10px;
  padding: 5px;
}
.audio-control button {
  margin: 10px;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  color: white;
  cursor: pointer;
  border: none;
}

.listening-active {
  background-color: darkred;
}

.listening-inactive {
  background-color: darkgreen;
}

.mute-active {
  background-color: darkred;
}

.mute-inactive {
  background-color: darkgreen;
}

.playback-active {
  background-color: darkorange;
}

.playback-inactive {
  background-color: gray;
}

.playback-muted {
  background-color: darkred;
}

.playback-ready {
  background-color: darkgreen;
}

.playback-active {
  background-color: darkorange;
}

.listening-active {
  background-color: red;
}

.listening-inactive {
  background-color: green;
}

.mute-active {
  background-color: darkred;
}

.mute-inactive {
  background-color: darkgreen;
}
/* Adjust for Smaller Screens */
@media (max-width: 765px) {
  .responsive-input {
    max-width: 300px;
  }
  .chat-output {
    max-width: 250px;
  }
  .header-container img {
    max-width: 200%; /* Shrink the image on smaller screens */
    max-height: 50px; /* Lower the max height */
  }

}

@media (orientation: portrait) and (max-width: 450px) {
  .header-container img {
    max-width: 140%; /* Smaller width on very small screens */
    max-height: 35px; /* Reduce max height further */
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  .header-container img {
    max-width: 180%; /* Reduce for landscape orientation */
    max-height: 45px; /* Limit height */
  }
}

</style>
