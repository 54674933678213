<template>
  <div class="main-container">
  <custom-toast ref="customToast" />
  <!-- Popup card editor -->
  <div class="card-edit-overlay" v-if="isCardEditDialogOpen">
      <div class="card-edit-dialog">
        <h2>Edit Card</h2>
        <form @submit.prevent="saveCard(cardData)">

          <!-- Email -->
          <div class="form-group">
            <label for="card-email">Email</label>
            <input type="text" id="card-email" v-model="cardData.Email" required readonly :class="{'readonly-field': true}">
          </div>

          <!-- Category -->
          <div class="form-group">
            <label for="card-category">Category</label>
            <template v-if="!addingNewCategory">
              <select id="card-category" v-model="cardData.Category" @change="onCategoryChange">
                <option v-for="category in userCategories" :key="category" :value="category">{{ category }}</option>
                <option value="new">Add New Category...</option>
              </select>
              <button @click="addingNewCategory = true">Add New</button>
            </template>
            <template v-else>
              <input type="text" id="card-category" v-model="newCategory">
              <button @click="confirmNewCategory">Confirm</button>
            </template>
          </div>
          
          <!-- Subcategory -->
          <div class="form-group">
            <label for="card-subcategory">Subcategory</label>
            <template v-if="!addingNewSubcategory">
              <select id="card-subcategory" v-model="cardData.Subcategory">
                <option v-for="subcategory in categoryToSubcategories[cardData.Category]" :key="subcategory" :value="subcategory">{{ subcategory }}</option>
                <option value="new">Add New Subcategory...</option>
              </select>
              <button @click="addingNewSubcategory = true">Add New</button>
            </template>
            <template v-else>
              <input type="text" id="card-subcategory" v-model="newSubcategory">
              <button @click="confirmNewSubcategory">Confirm</button>
            </template>
          </div>

          <!-- Item -->
          <div class="form-group">
            <label for="card-item">Item</label>
            <input type="text" id="card-item" v-model="cardData.Item" required>
          </div>

          <!-- Description -->
          <div class="form-group">
            <label for="card-description">Description</label>
            <textarea id="card-description" v-model="cardData.Description" required></textarea>
          </div>

          <!-- Image URL -->
          <div class="form-group">
            <label for="card-image">Image URL</label>
            <input type="text" id="card-image" v-model="cardData.Image" required>
          </div>

          <!-- Details -->
          <div class="form-group">
            <label for="card-details">Details</label>
            <textarea id="card-details" v-model="cardData.Details" required></textarea>
          </div>

          <!-- Dialog Buttons -->
          <div class="dialog-buttons">
            <button type="button" class="cancel-button" @click="cancelEditCard">Cancel</button>
            <button type="button" class="delete-button" @click="deleteCard">Delete</button>
          </div>
        <button type="submit">Save Changes</button>

        </form>
      </div>
    </div>


<!-- Header Section -->
  <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image">
    </div>

  <div class="card-container">
    <h1>{{ msg }}</h1>

    <!-- Loading Indicator -->
    <div v-if="isLoading">
      <p>Loading...</p> <!-- You can style this as needed or use a spinner -->
    </div>

    <!-- Content to show when not loading -->
    <div v-else>
      <div class="button-container-top">
        <button class="button" :class="{ 'green-button': !isShowingUserCards, 'red-button': isShowingUserCards }" @click="showAllCardPairs">Show All Card Pairs</button>
        <button class="button" :class="{ 'green-button': isShowingUserCards, 'red-button': !isShowingUserCards }" @click="showYourCardPairs">Show Only Your Card Pairs</button>
     </div>

      <div class="pair-search-container">
        <input type="text" v-model="searchPairs" placeholder="Search Card Pairs - start typing..." />
      </div>

      <div class="pair-list">
        <div v-for="pair in filteredPairs" :key="pair.name" class="category-subcategory-pair">
          <span>{{ pair.name }}</span>
          <div :class="['selection-dot', {'selected-pair': selectedPairs.has(pair.name)}]" @click="togglePairSelection(pair)"></div>
        </div>
      </div>

      <div class="button-container-bottom">
        <div class="select-all-border">
          <div class="select-all-toggle" @click="toggleSelectAll">
            <div :class="{ 'selected': allSelected }"></div>
            Select All
          </div>
        </div>

        <button class="manual-edit-btn" @click="showSelectedCards">Show Selected Cards</button>
        <button class="manual-edit-btn" @click="addCardsToCollection">Add Selected Cards to Your Collection</button>
        <button v-if="isShowingUserCards" class="manual-edit-btn" @click="openNewCardDialog">Generate a New Card</button>
        <!-- <button class="manual-edit-btn" @click="editSelectedCard">Edit Selected Card</button>-->
        <button v-if="isAnySelectedCardMine" class="manual-edit-btn" @click="editSelectedCard">Edit Selected Card</button>


        <button v-if="isShowingUserCards" class="delete-btn" @click="deleteSelectedCards">Delete Selected Cards from Your Collection</button>
        <button class="sign-out-btn" @click="clearSelection">Clear Selection</button>
      </div>

      <div class="card-search-container">
        <input type="text" v-model="searchCards" placeholder="Search Card Items - start typing..." />
      </div>

      <div v-for="item in filteredCards" :key="item.id" class="card">
        <div class="selection-dot" :class="{'selected-card': selectedCards.has(item.id)}" @click.stop="toggleCardSelection(item.id)"></div>

              <!-- Editable card fields -->
        <div v-if="editableStates[item.id]">
          <input type="text" v-model="item.data.Email" class="card-section email" placeholder="Email" />
          <input type="text" v-model="item.data.Category" class="card-section" placeholder="Category" />
          <input type="text" v-model="item.data.Subcategory" class="card-section subcategory" placeholder="Subcategory" />
          <input type="text" v-model="item.data.Item" class="card-section item" placeholder="Item" />
          <textarea v-model="item.data.Description" class="card-section description" placeholder="Description"></textarea>
          <input type="text" v-model="item.data.Image" class="card-section image" placeholder="Image URL" />
          <textarea v-model="item.data.Details" class="card-section details" placeholder="Details"></textarea>
          <button class="save-btn" @click="saveCard(item)">Save</button>
        </div>

        <!-- Non-editable card fields -->
        <div v-else>
          <div class="card-content">
            <!-- Top Row: Email, Category, and Item -->
            <div class="card-row">
              <div class="card-section email">{{ item.data.Email }}</div>
              <div class="card-section">{{ item.data.Category }}</div>
              <div class="card-section item">{{ item.data.Item }}</div>
            </div>

            <!-- Second Row: Description -->
            <div class="card-row">
              <div class="card-section description">{{ item.data.Description }}</div>
            </div>

            <!-- Third Row: Details and Image on the same line -->
            <div class="card-row bottom-row">
              <div class="card-section details">{{ item.data.Details }}</div>
              <div class="card-image">
                <img :src="item.data.Image" @error="handleImageError" alt="Item Image">
              </div>
            </div>
          </div>

          <button v-if="item.data.Email === currentUserEmail" class="edit-btn" @click="toggleEditMode(item.id)">Edit</button>
        </div>
      </div> <!-- End of card loop -->
    </div> <!-- End of v-else -->
  </div> <!-- End of card-container -->
  </div>

</template>

<script>
import {onAuthStateChanged} from 'firebase/auth';
import {auth, db} from '@/firebaseConfig'; // Use named import for db
import {collection, query, where, getDocs, updateDoc} from 'firebase/firestore';
import {doc, getDoc, setDoc, deleteDoc} from 'firebase/firestore';
import CustomToast from '@/components/CustomToast.vue';

export default {
  name: 'HelloWorld',
  components: {
    CustomToast, // Ensure you've renamed Toast.vue to AppToast.vue
  },
  props: {
    msg: String,
  },
  data() {
    return {
      items: [],
      categoriesWithSubcategories: [], // Store category and subcategory pairs
      selectedPairs: new Set(), // Store selected category-subcategory pairs
      selectedCards: new Set(), // Select cards to be added to your collection
      isShowingUserCards: false, // Tracks which button is active
      allSelected: false,
      searchPairs: '',
      searchCards: '',
      currentUserEmail: null,
      currentUserName: null,
      editableStates: {}, // Tracks edit mode for each card
      isLoading: true, // Initialize isLoading as true
      isCardEditDialogOpen: false, // To control the visibility of the card edit dialog
      cardData: { /* Initialize with your card structure */ }, // Data model for the card being edited
      userCategories: [],
      userSubcategories: [],
      categoryToSubcategories: {}, // Maps categories to their subcategories
      newCategoryInput: '',
      addingNewCategory: false,
      addingNewSubcategory: false,
      newCategory: '',
      newSubcategory: '',

    };
  },
  // ... rest of your component (methods, etc.) ...
 computed: {
    filteredPairs() {
      if (!this.searchPairs) {
        return this.categoriesWithSubcategories;
      }
      return this.categoriesWithSubcategories.filter(pair => {
        return pair.name.toLowerCase().includes(this.searchPairs.toLowerCase());
      });
    },
    filteredCards() {
      // Assuming `searchCards` is the model for a search input field
      if (!this.searchCards) {
        return this.items; // Return all if no search term
      }
      return this.items.filter(item => {
        return item.data.Item.toLowerCase().includes(this.searchCards.toLowerCase());
      });
    },
    isAnySelectedCardMine() {
      return this.items.some(item =>
        this.selectedCards.has(item.id) && item.data.Email === this.currentUserEmail
      );
    }
  },

  created() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.currentUserEmail = user.email;

        // Fetch additional user details from Firestore
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          this.currentUserName = userDoc.data().username; // Make sure 'username' field exists
        } else {
          console.log('User document does not exist');
          this.currentUserName = null;
        }
      } else {
        this.currentUserEmail = null;
        this.currentUserName = null;
      }
    });

  },

  methods: {
    openNewCardDialog() {
      this.cardData = { Email: this.currentUserEmail }; // Default email to current user's email
      this.isCardEditDialogOpen = true; // Reuse the same dialog for new card
    },
    openEditDialog(cardId) {
      const card = this.items.find(item => item.id === cardId);
      if (card) {
        this.cardData = { ...card.data, id: card.firestoreDocumentId }; // Use the correct field for the ID
        this.isCardEditDialogOpen = true;
      } else {
        console.error('Card not found');
      }
    },
    async fetchUserCategoriesAndSubcategories() {
      const categories = new Set();
      const categoryToSubcategoriesMap = {};

      const q = query(collection(db, "cards"), where("Email", "==", this.currentUserEmail));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        categories.add(data.Category);

        if (!categoryToSubcategoriesMap[data.Category]) {
          categoryToSubcategoriesMap[data.Category] = new Set();
        }
        categoryToSubcategoriesMap[data.Category].add(data.Subcategory);
      });

      this.userCategories = Array.from(categories);
      this.categoryToSubcategories = Object.fromEntries(
        Object.entries(categoryToSubcategoriesMap).map(([key, value]) => [key, Array.from(value)])
      );
    },
    toggleNewCategory() {
        if (this.newCategory) {
          // Switching from new category input to dropdown
          this.newCategory = false;
          this.newCategoryInput = ''; // Reset new category input
        } else {
          // Switching from dropdown to new category input
          this.newCategory = true;
          this.newCategoryInput = this.cardData.Category; // Copy the current category to the input
        }
      },

      onCategoryChange() {
        if (this.cardData.Category === 'new') {
          this.addingNewCategory = true;
          this.newCategory = '';
        }
      },

      confirmNewCategory() {
        if (this.newCategory.trim() !== '') {
          this.cardData.Category = this.newCategory.trim();
          this.addingNewCategory = false;
          // Optionally add the new category to userCategories
          this.userCategories.push(this.newCategory.trim());
        } else {
          alert('Please enter a category name.');
        }
      },

  confirmNewSubcategory() {
    if (this.newSubcategory.trim() !== '') {
      this.cardData.Subcategory = this.newSubcategory.trim();
      this.addingNewSubcategory = false;
      // Optionally add the new subcategory to categoryToSubcategories
      if (!this.categoryToSubcategories[this.cardData.Category]) {
        this.categoryToSubcategories[this.cardData.Category] = [];
      }
      this.categoryToSubcategories[this.cardData.Category].push(this.newSubcategory.trim());
    } else {
      alert('Please enter a subcategory name.');
    }
  },

    toggleCardEditDialog(cardId) {
      this.isCardEditDialogOpen = !this.isCardEditDialogOpen;
      if (this.isCardEditDialogOpen) {
        const card = this.items.find(item => item.id === cardId);
        if (card) {
          this.cardData = { ...card.data, id: cardId }; // Include the id
        } else {
          console.error('Card not found');
          this.isCardEditDialogOpen = false;
        }
      }
    },
    cancelEditCard() {
      this.isCardEditDialogOpen = false;
    },
    // Update the card data
  async updateCard() {
    console.log('Updating card with data:', this.cardData);
    console.log('Updating card with id:', this.cardData.id);

    if (!this.cardData.id) {
      console.error('No valid card ID provided');
      return;
    }
    // Implement the logic to update the card
    // Example:
    try {
      const cardRef = doc(db, 'cards', this.cardData.id);
      await updateDoc(cardRef, this.cardData);
      this.$refs.customToast.showToast("Card updated successfully");
    } catch (error) {
      console.error("Error updating card:", error);
      this.$refs.customToast.showToast("Error updating card", "error");
    }
    this.isCardEditDialogOpen = false;
  },

  // Delete the card
  async deleteCard() {
    // Implement the logic to delete the card
    // Example:
    try {
      await deleteDoc(doc(db, "cards", this.cardData.id));
      this.$refs.customToast.showToast("Card deleted successfully");
    } catch (error) {
      console.error('Error deleting card:', error);
      this.$refs.customToast.showToast("Error deleting card", "error");
    }
    this.isCardEditDialogOpen = false;
  },
    async fetchCardPairs() {
  // Logic to fetch and process card pairs
      const categorySubPairs = new Set();
      const q = query(collection(db, "cards"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(doc => {
        const docData = doc.data();
        categorySubPairs.add(`${docData.Category} - ${docData.Subcategory}`);
    });

    this.categoriesWithSubcategories = Array.from(categorySubPairs).map(pair => ({ name: pair }));
  },

  async fetchAllCards() {
    // Logic to fetch and process all cards
    this.items = [];
    const q = query(collection(db, "cards"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(doc => {
      this.items.push({
        id: doc.id,
        data: doc.data(),
        firestoreDocumentId: doc.id
      });
    });
  },
    toggleSelectAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        // Add all card IDs to selectedCards
        this.selectedCards = new Set(this.items.map(item => item.id));
      } else {
        // Clear selectedCards
        this.selectedCards.clear();
      }
    },
    handleImageError(event) {
      console.log('Image load error:', event.target.src);
      event.target.style.display = 'none';
    },
    clearSelection() {
      this.selectedPairs.clear();
      this.selectedCards.clear();
      this.searchPairs = ''; // Reset the search input for pairs
      this.searchCards = ''; // Reset the search input for cards
      this.items = [];
    },
    togglePairSelection(pair) {
  if (this.selectedPairs.has(pair.name)) {
    this.selectedPairs.delete(pair.name);
  } else {
    this.selectedPairs.add(pair.name);
  }

  // Vue 2.x reactivity caveat fix
  this.selectedPairs = new Set(this.selectedPairs);
    },
    toggleCardSelection(cardId) {
      console.log(`Toggling selection for card ID: ${cardId}`);

      if (this.selectedCards.has(cardId)) {
        this.selectedCards.delete(cardId);
      } else {
        this.selectedCards.add(cardId);
      }
    // Log the action
    console.log(`Toggling selection for card ID: ${cardId}`);
    console.log('After toggling, selectedCards:', Array.from(this.selectedCards));

    // Force Vue to react to the change
    this.selectedCards = new Set(this.selectedCards);

    // Log after updating the set
    console.log('After updating set, selectedCards:', Array.from(this.selectedCards));
  },
  async showSelectedCards() {
  console.log('Selected Pairs:', Array.from(this.selectedPairs));

  this.items = []; // Clear current items

  // Iterate over each pairName in the selectedPairs Set
  for (let pairName of this.selectedPairs) {
    if (typeof pairName === 'string') {
      const [category, subcategory] = pairName.split(' - ');

      try {
        let q;
        if (this.isShowingUserCards && this.currentUserEmail) {
          // Query for cards in the pair that belong to the current user
          q = query(collection(db, "cards"),
                    where("Category", "==", category),
                    where("Subcategory", "==", subcategory),
                    where("Email", "==", this.currentUserEmail));
        } else {
          // Query for all cards in the pair
          q = query(collection(db, "cards"),
                    where("Category", "==", category),
                    where("Subcategory", "==", subcategory));
        }

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
          const formattedUrl = this.formatImageUrl(doc.data().Image);
          this.items.push({ id: doc.id, data: doc.data(), firestoreDocumentId: doc.id, Image: formattedUrl });
        });
      } catch (error) {
        console.error(`Error fetching data for pair ${pairName}:`, error);
      }
    } else {
      console.error('Invalid pairName:', pairName);
    }
  }
},
formatImageUrl(url) {
    // Example implementation: just returns the URL as is.
    // You can add your URL processing logic here.
    return url;
  },
  async showAllCardPairs() {
  this.items = []; // Clear current items before fetching new data
  this.isLoading = true;
  this.isShowingUserCards = false;

  try {
    const q = query(collection(db, "cards"));
    const querySnapshot = await getDocs(q);
    this.processQuerySnapshot(querySnapshot);
  } catch (error) {
    console.error('Error fetching all card pairs:', error);
  } finally {
    this.isLoading = false; // Set loading to false regardless of outcome
  }

  // Initialize editable states
  this.editableStates = this.items.reduce((states, item) => {
    states[item.id] = false; // Initially, all cards are not editable
    return states;
  }, {});
},

  async showYourCardPairs() {
    this.items = []; // Clear current items before fetching new data
    this.isLoading = true;
    this.isShowingUserCards = true;

    try {
      const userEmail = this.currentUserEmail;
      if (!userEmail) {
        console.log('No user email available.');
        return;
      }
      const q = query(collection(db, "cards"), where("Email", "==", userEmail));
      const querySnapshot = await getDocs(q);
      this.processQuerySnapshot(querySnapshot);

      // Fetch categories and subcategories for the user
      await this.fetchUserCategoriesAndSubcategories();
    } catch (error) {
      console.error('Error fetching your card pairs:', error);
    } finally {
      this.isLoading = false; // Set loading to false regardless of outcome
    }

    // Initialize editable states
    this.editableStates = this.items.reduce((states, item) => {
      states[item.id] = false; // Initially, all cards are not editable
      return states;
    }, {});
  },


  toggleEditMode(cardId) {
    const card = this.items.find(item => item.id === cardId);
    if (card) {
    this.cardData = { ...card.data, id: cardId };
     } else {
      console.error('Card not found');
    }

    if (card.data.Email !== this.currentUserEmail) {
      alert("You can only edit your own cards.");
      return;
    }

    this.cardData = { ...card.data, id: cardId }; // Include the id
    this.editableStates[cardId] = true;
  },

  async addCardsToCollection() {
    if (!this.currentUserEmail) {
      console.error('User email is not available');
      return;
    }

  let totalSelected = this.selectedCards.size;
  let addedCount = 0;
  let duplicateCount = 0;

  console.log('Selected card IDs:', Array.from(this.selectedCards));
  for (let cardId of this.selectedCards) {
    const card = this.filteredCards.find(item => item.id === cardId) || this.items.find(item => item.id === cardId);
    if (card) {
      const cardExists = await this.checkCardExistsInUserCollection(card.data.Category, card.data.Subcategory, card.data.Item);
      if (!cardExists) {
        const newCard = { ...card.data, Email: this.currentUserEmail, Username: this.currentUserName || 'Unknown' };
        await setDoc(doc(collection(db, "cards")), newCard); // Replace 'userCards' with your collection name
        addedCount++;
      } else {
        duplicateCount++;
      }
    }
  }
  this.selectedCards.clear(); // Clear the selection after adding

  // Prepare the toast message
let toastMessage = `${addedCount} out of ${totalSelected} selected cards successfully added to your collection.`;
if (duplicateCount > 0) {
  toastMessage += ` ${duplicateCount} duplicate card(s) skipped.`;
}

  console.log(toastMessage);
  this.$refs.customToast.showToast(toastMessage);
},

  async checkCardExistsInUserCollection(category, subcategory, item) {
  const q = query(collection(db, "cards"),
                  where("Email", "==", this.currentUserEmail),
                  where("Category", "==", category),
                  where("Subcategory", "==", subcategory),
                  where("Item", "==", item));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
},
async saveCard(card) {
  console.log("Card data received in saveCard:", card); // Debugging line

  try {
    let cardRef;
    let isDuplicate = false;

    // Check if it's a new card (no id present)
    if (!card.id) {
      // Query for duplicate card
      isDuplicate = await this.checkForDuplicateCard(card.Category, card.Subcategory, card.Item);
      if (isDuplicate) {
        // If duplicate exists, confirm with the user
        if (!confirm("A card with the same Category, Subcategory, and Item already exists. Are you sure you want to add a new card with the same details?")) {
          return; // Abort if user chooses not to proceed
        }
      }
      cardRef = doc(collection(db, "cards")); // Reference for a new card
    } else {
      cardRef = doc(db, 'cards', card.id); // Reference for an existing card
    }

    // Prepare data for update or new entry, exclude any non-data fields
    let dataToUpdate = { ...card };
    delete dataToUpdate.id;
    delete dataToUpdate.firestoreDocumentId;

    if (!card.id) {
      // Set the user's email for new card
      dataToUpdate.Email = this.currentUserEmail;
      await setDoc(cardRef, dataToUpdate); // Adding new card
    } else {
      await updateDoc(cardRef, dataToUpdate); // Updating existing card
    }

    // Update or add the card in the local items array
    if (card.id) {
      this.items = this.items.map(item =>
        item.id === card.id ? { ...item, data: dataToUpdate } : item
      );
      this.editableStates[card.id] = false; // Reset editable state for this card
    } else if (!isDuplicate) {
      this.items.push({ id: cardRef.id, data: dataToUpdate }); // Add new card to local array
    }

    // Show success notification
    let message = card.id ? "Card updated successfully" : "New card added successfully";
    this.$refs.customToast.showToast(message);
    } catch (error) {
      console.error("Error saving card:", error);
      this.$refs.customToast.showToast("Error saving card", "error");
    }
  },

async checkForDuplicateCard(category, subcategory, item) {
  const q = query(collection(db, "cards"),
                  where("Category", "==", category),
                  where("Subcategory", "==", subcategory),
                  where("Item", "==", item));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if duplicate exists
  },

  async deleteSelectedCards() {
    if (!this.currentUserEmail) {
      console.error('User email is not available');
      return;
    }

    if (!confirm('Are you sure you want to delete the selected cards?')) {
      return; // Stop if the user cancels
    }

    const deletionPromises = Array.from(this.selectedCards).map(cardId => {
      const card = this.items.find(item => item.id === cardId);
      if (card && card.firestoreDocumentId) {
        return deleteDoc(doc(db, "cards", card.firestoreDocumentId));
      } else {
        console.error(`Invalid or missing document ID for card with ID ${cardId}`);
        return Promise.resolve(); // Resolve for invalid/missing IDs
      }
    });

    try {
      await Promise.all(deletionPromises);
      console.log('Selected cards deleted from your collection');
    } catch (error) {
      console.error('Error deleting cards:', error);
    }

    this.selectedCards.clear(); // Clear the selection

    // Refresh the card list
    if (this.isShowingUserCards) {
      await this.showYourCardPairs();
    } else {
      await this.showAllCardPairs();
    }
  },
  processQuerySnapshot(querySnapshot) {
  const categorySubPairsCount = {};
  querySnapshot.forEach(doc => {
    const pairKey = `${doc.data().Category} - ${doc.data().Subcategory}`;
    categorySubPairsCount[pairKey] = (categorySubPairsCount[pairKey] || 0) + 1;
  });

  this.categoriesWithSubcategories = Object.keys(categorySubPairsCount).map(key => ({
    name: key,
    count: categorySubPairsCount[key],
  }));

  console.log(this.categoriesWithSubcategories); // Add this line to debug
    },
  },

  async mounted() {
    this.isLoading = true;
    try {
      await this.fetchCardPairs(); // Only fetch categories and subcategories
      // Do NOT fetch all cards here
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style>
.main-container {
    border: 1px solid #ccc; /* Adds a light grey border */
    margin-left: 10px; /* Adds left margin */
    margin-right: 10px; /* Adds right margin */
    padding: 10px; /* Optional: Adds some padding inside the container */
  }
/* Header Styles */
/*
.header-container {
  position: fixed; /* Fixed position at the top */
  /*top: 0;  Aligns the header to the top of the viewport */
  /*left: 0; /* Aligns the header to the left of the viewport */
  /*right: 0; /* Ensures the header spans the entire width */
  /*z-index: 1; /* Keeps the header above other content */

  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 100%; /* Full width */
  /*overflow: hidden; /* This will hide any part of the image that overflows the container */
  /*background-color: #21244A; /* Replace with your desired color */
/*}
*/

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1vh;
}

.top-section {
  display: flex;
  width: 100%;
  /* Removed fixed height to allow dynamic adjustment */
}
.pair-search-container, .card-search-container {
  text-align: left; /* Aligns content to the left */
  width: 100%; /* Ensures the container takes full width */
}
.pair-search-container input, .card-search-container input {
  width: 100%; /* Makes the input take the full width of the container */
  height: 20px; /* Increase the height as needed */
  padding: 5px 5px; /* Adjust padding for better text alignment and appearance */
  margin: 5px 0; /* Optional: Adds margin around each input for spacing */
  font-size: 16px;
  /* Other styling as needed (e.g., border, etc.) */
}

.card-search-container,
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto; /* Makes the card list scrollable */

  padding-bottom: 10px; /* Add padding equal to the height of the bottom button container */
  position: relative; /* Use relative if sticky causes overlap issues */
  top: 0; /* Adjust as needed, or remove if using position: relative */
  bottom: 0%; /* Stick to the bottom of the viewport */
  z-index: 1; /* Ensure it stays on top of the scrolling content */

  max-height: calc(100vh - 200px); /* Adjust based on other elements' heights */

}
.select-all-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.select-all-toggle div {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid grey;
  margin-right: 10px;
}

.selected {
  background-color: #013220;
}
.select-all-border {
  border: 2px solid #ccc;  /* Adjust the border style as needed */
  padding: 4px 3px;       /* Adjust padding for visual appeal */
  display: inline-flex;
  align-items: center;     /* Ensures vertical alignment */
  border-radius: 5px;      /* Rounded corners */
  margin-top: 0px; /* Space above the box */
}
.card {
  display: flex;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-row.bottom-row {
  justify-content: space-between; /* Aligns details and image on opposite ends */
}

.card-section {
  margin-right: 20px;
}

.card-section.description {
  flex-grow: 1; /* Allows description to take up more width */
}

.card-section.details {
  flex-grow: 2; /* Adjust as needed */
}

.card-image {
  flex-shrink: 0;
  margin-left: 20px;
  align-self: start;
  max-width: 150px; /* Adjust image size as needed */
  height: auto;
  border-radius: 8px;
}

.card-image img {
  max-width: 150px; /* Adjust image size as needed */
  height: auto;
  border-radius: 8px; /* Optional for styled image corners */
}
.username {
  flex-basis: 100%;
}
.category, .subcategory, .item, .description, .image {
  flex: 1;
}
.details {
  flex-basis: 100%;
}

.pair-list {

  border: 1px solid #ccc;
  margin-top: 0px;
  width: 100%; /* Ensure full width */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: sticky;
  bottom: 20%; /* Stick to the bottom of the viewport */
  z-index: 1; /* Ensure it stays on top of the scrolling content */
  max-height: 200px; /* Adjust based on desired height */
  overflow-y: auto;
  margin-bottom: 10px;
}
.button, .manual-edit-btn, .delete-btn, .sign-out-btn {

  display: inline-block; /* Display buttons inline */
  margin: 5px; /* Optional: Adjust the space between buttons */
  }
.button-container-top {
  text-align: right; /* Center the buttons horizontally */
  margin-bottom: 10px; /* Spacing between the buttons and the pair list */
  display: flex;
  align-items: center; /* Aligns children vertically in the center */
  position: sticky;
  top: 0px; /* Adjust this value based on the actual height of your header */
  background: #fff; /* Or any color that matches your design */
  z-index: 2;
  padding-top: 0px; /* Optional spacing */
  padding-bottom: 0px; /* Optional spacing */
}
.button-container-bottom {
  text-align: right; /* Center the buttons horizontally */
  width: 100%;
  margin-top: 10px; /* Spacing between the pair list and the buttons */
  display: flex;
  align-items: center; /* Aligns children vertically in the center */
  position: sticky;
  top: 50px; /* Replace with the actual height of your header image + any additional space */
  bottom: 0; /* Stick to the bottom of the viewport */
  z-index: 2; /* Ensure it stays on top of the scrolling content */
  background: #95F2FE; /* Or the background color of your app */
  padding-top: 10px; /* Optional spacing */
  padding-bottom: 10px; /* Optional spacing */
}
.category-subcategory-pair {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left; /* Align content to the left */
}

.selection-dot {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
}

.selected-card {
  background-color: #013220;
  /* Ensure this style is significantly different from the non-selected state */
}
.selected-pair {
  background-color: #013220;
}
.green-button {
  background-color: #013220;
  color: white;
}
.red-button {
  background-color: #800000;
  color: white;
}
.delete-btn {
  background-color: darkslateblue;
  color: white;
  /* Style for the delete button */
}
.readonly-field {
  background-color: lightgray;
  color: black;
}
@media (max-width: 768px) {
  .header-container {
    position: relative; /* Fixed position at the top */
    top: 0; /* Aligns the header to the top of the viewport */
    left: 0; /* Aligns the header to the left of the viewport */
    right: 0; /* Ensures the header spans the entire width */
    z-index: 1000; /* Keeps the header above other content */
  }
  .card {
    flex-direction: column;
  }

  .card-image {
    margin-left: 0;
    margin-top: 10px;
  }

  .card-image img {
    max-width: 100%;
  }

  .card-section {
    font-size: 14px; /* Smaller font size for mobile */
  }
.button, .manual-edit-btn, .delete-btn, .sign-out-btn {
  font-size: 12px !important;
  padding: 5px 8px !important;
  display: inline-block; /* Display buttons inline */
  margin: 5px; /* Optional: Adjust the space between buttons */
  }
.green-button {
  font-size: 12px !important;
  padding: 5px 8px !important;
  max-height: 24px;
  display: inline-block; /* Display buttons inline */
  margin: 5px; /* Optional: Adjust the space between buttons */
}
.red-button {
  font-size: 12px !important;
  padding: 5px 8px !important;
  max-height: 24px;
  display: inline-block; /* Display buttons inline */
  margin: 5px; /* Optional: Adjust the space between buttons */
}
  .pair-search-container input, .card-search-container input {
    height: 20px; /* Slightly smaller input fields */
  }

  .select-all-toggle div {
    ont-size: 12px !important;
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    /* Adjust other styles if necessary */
  }
  .button-container-top,
  .button-container-bottom {
    position: relative; /* Remove sticky position for small screens */
    top: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: column; /* Stack buttons vertically */

    max-height: calc(100vh - 140px); /* Adjust for smaller screens */
    display: inline-block; /* Display buttons inline */
    margin: 5px; /* Optional: Adjust the space between buttons */
    background-color: #95F2FE;
  }
}
</style>
