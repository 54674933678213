<template>
  <!--<user-notes :selectedFont="font" @update:selectedFont="font = $event"></user-notes>-->
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">

  <!-- Header Section -->
  <div class="header-container">
    <img alt="Vue logo" src="../assets/web_header-notes.png" class="header-image">
  </div>

  <div>
    <div class="notes-controls">
       <input v-model="newNoteName"
         style="color: black;"
         placeholder="Enter New note name here..."
         class="note-input input-field" />
      <div class="color-picker-wrapper">
        <input type="color" v-model="noteColor" class="color-picker" />
        <span class="color-picker-text">Color</span>
      </div>
    <button class="add-note-button" @click="addNote">Add Note</button>
    </div>
    <input v-model="searchQuery" placeholder="Search notes..." class="search-input input-field" />
      <button @click="exportNotesToPlainText" class="export-button">Export Notes as Text TEST</button>

  <div class="notes-container" ref="notesContainer">
      <div v-for="note in filteredNotes"
           :key="note.id"
           :class="['note', { 'note-being-edited': currentlyEditingNoteId === note.id }]"
            :style="{ backgroundColor: note.color }">
        <!-- Note header and action buttons at the top -->
        <div class="note-header">
          <span :class="{'red-dot': note.saveStatus === 'unsaved', 'green-dot': note.saveStatus === 'saved'}"></span>
          <input type="text"
           v-model="note.name"
           @input="adjustInputWidth(note)"
           :ref="'noteInput' + note.id"
           :style="{ backgroundColor: note.color, color: note.color ? (isColorDark(note.color) ? 'white' : 'black') : 'black' }"
           class="note-name-input"/>
          <input type="color" v-model="note.color" @change="updateNoteColor(note)" class="color-picker" />
        </div>

        <!-- Action buttons at the top -->
        <div class="note-actions">
          <button @click="saveNote(note)" :class="['save-note-button', note.saveButtonClass]">Save Note</button>
          <button @click="deleteNote(note.id)" class="delete-note-button">Delete Note</button>
          <button @click="toggleNoteVisibility(note)" class="hide-note-button">{{ note.isVisible ? 'Hide' : 'Unhide' }}</button>
        </div>

        <!-- Conditional CKEditor and bottom action buttons when note is unhidden -->
        <div v-show="note.isVisible">
          <!-- CKEditor Toolbar at the top -->
          <ckeditor :editor="editor"
                    v-model="note.content"
                    :key="note.id"
                    :id="'editor' + note.id"
                    :config="editorConfig"
                    @ready="onEditorReady(note.id)"
                    @focus="handleFocus(note)"
                    @blur="handleBlur(note)"
                    @input="handleInput(note)">
          </ckeditor>

          <!-- Bottom toolbar container with dynamic ID -->
          <div class="bottom-toolbar-container">
            <div :id="'bottom-toolbar-' + note.id"></div> <!-- Bottom toolbar container with dynamic ID -->
          </div>

          <!-- Action buttons at the bottom -->
          <div class="note-actions" v-show="note.isVisible">
            <button @click="saveNote(note)" :class="['save-note-button', note.saveButtonClass]">Save Note</button>
            <button @click="deleteNote(note.id)" class="delete-note-button">Delete Note</button>
            <button @click="toggleNoteVisibility(note)" class="hide-note-button">{{ note.isVisible ? 'Hide' : 'Unhide' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '../assets/ckeditor/ckeditor.js';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebaseConfig';
import { collection, query, where, orderBy, onSnapshot, addDoc, serverTimestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth } from '@/firebaseConfig';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import Isotope from 'isotope-layout';
import CryptoJS from 'crypto-js';

class MyUploadAdapter {
  constructor(loader, userEmail) {
    this.loader = loader;
    this.userEmail = userEmail; // Store userEmail in the adapter instance
  }

  async upload() {
    const file = await this.loader.file;
      // Resize the image to a max width of 800px, max height of 600px, and quality of 0.7
      const resizedImage = await resizeImage(file, 800, 600, 0.7);

      const imageUrl = await this.uploadImageToFirebase(resizedImage); // Make sure to adjust this method to accept a Blob
      return { default: imageUrl };
    }

  async uploadImageToFirebase(file) {
    // Use the userEmail and timestamp to create a unique path for each uploaded image
    const storage = getStorage();
    const timestamp = new Date().getTime();
    const filePath = `smartflash_objects/usernote_images/${this.userEmail}_${timestamp}_${file.name}`;
    const storageReference = storageRef(storage, filePath);

    const uploadTaskSnapshot = await uploadBytesResumable(storageReference, file);
    const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
    return imageUrl;
  }
}

function resizeImage(file, maxWidth, maxHeight, quality) {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      // Create a canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate the new image dimensions
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Set canvas dimensions
      canvas.width = width;
      canvas.height = height;

      // Draw the image on canvas
      ctx.drawImage(img, 0, 0, width, height);

      // Compress the image and resolve the blob
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', quality);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

// Modify the plugin function to create an adapter instance with the user
function MyCustomUploadAdapterPlugin(editor) {
  const auth = getAuth();
  const userEmail = auth.currentUser; // Get the currently logged-in user

  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader, userEmail); // Pass the user here
  };
}
export default {
  name: 'UserNotes',
  props: {
    selectedFont: String,
    selectedBackgroundColor: {
      type: String,
      default: 'lightgreen' // default color if not provided
    },
  },
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      saveDebounce: null,
      newNoteName: '', // Data property to hold the new note name
      noteColor: '#E5FFFD', // Default color
      editor: ClassicEditor,
      editorConfig: {
        contentStyle: `body { background-color: ${this.lightenColor(this.noteColor, 20)}; }`,
        extraPlugins: [MyCustomUploadAdapterPlugin],
        toolbar: [
            'paragraph', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'todoList', // Added todoList for checkboxes
            '|', 'undo', 'redo', 'imageUpload', 'insertTable', 'mediaEmbed',
            '|', 'alignment', 'fontSize', 'fontFamily', 'highlight'
          ],
        image: {
          styles: ['alignLeft', 'alignCenter', 'alignRight'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
      },

      notes: [
        {
          content: '', // The content of the note
          isVisible: false, // Initially set to false to hide the note
          saveStatus: 'saved',
          saveButtonClass: 'saved',
        },
      ],
      searchQuery: '',
      saveTimeout: null,
      isInitialSave: true, // Flag to check if it's the first save
      availableFonts: ['Arial', 'Times New Roman', 'Courier', 'Georgia', 'Verdana'],
      // Initialize local data property with the value of the prop
      localSelectedFont: this.selectedFont, //initialize from the prop
      currentlyEditingNoteId: null,
      //noteInputWidths: {}, // Object to store input widths keyed by note ID
      user: null,
      passphrases: {}, // Object to store passphrases temporarily
    };
  },
  watch: {
      'noteColor': function(newVal) {
      this.editorConfig = {
        ...this.editorConfig,
        contentStyle: `body { background-color: ${this.lightenColor(newVal, 20)}; }`
      };
    },
    selectedFont(newVal) {
      this.localSelectedFont = newVal;
    },
    notes(newNotes) {
      this.$nextTick(() => {
        newNotes.forEach(note => {
          this.updateEditorBackground(note);
        });
      });
    }
  },


  methods: {
    cloneToolbar(noteId) {
      this.$nextTick(() => {
        const editorElement = document.querySelector(`#editor${noteId}`);
        const toolbar = editorElement ? editorElement.querySelector('.ck-toolbar') : null;

        if (toolbar) {
          const clonedToolbar = toolbar.cloneNode(true);
          const bottomToolbarContainer = document.querySelector(`#bottom-toolbar-${noteId}`);

          if (bottomToolbarContainer) {
            bottomToolbarContainer.innerHTML = ''; // Clear any existing content
            bottomToolbarContainer.appendChild(clonedToolbar); // Append the cloned toolbar
          }
        }
      });
    },

    // Hook for CKEditor when it's fully initialized
    onEditorReady(noteId) {
      this.cloneToolbar(noteId);  // Ensure toolbar is cloned after CKEditor is ready
    },

    handleFocus(note) {
      this.currentlyEditingNoteId = note.id; // Track the currently edited note

      // Add the sticky class to the current note being edited
      this.$nextTick(() => {
        const noteElement = document.querySelector(`.note[data-id="${note.id}"]`);
        if (noteElement) {
          noteElement.classList.add('note-being-edited');
        }
      });
    },

    handleBlur(note) {
      // Remove the sticky class when the note loses focus
      const noteElement = document.querySelector(`.note[data-id="${note.id}"]`);
      if (noteElement) {
        noteElement.classList.remove('note-being-edited');
      }

      // Save the note if it was edited
      if (note.isEdited) {
        clearTimeout(this.saveDebounce);
        this.saveDebounce = setTimeout(() => this.saveNote(note), 300);
      }
    },

    lightenColor(color, percent) {
      if (!color || !color.startsWith('#') || color.length !== 7) {
        console.error("Invalid color provided to lightenColor:", color);
        return '#FFFFFF'; // Return default color if input is invalid
      }

      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R < 255) ? R : 255;
      G = (G < 255) ? G : 255;
      B = (B < 255) ? B : 255;

      const RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
      const GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
      const BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

      return "#" + RR + GG + BB;
    },

    isColorDark(color) {
      if (!color || typeof color !== 'string' || color.length !== 7) {
        // Return a default, or handle the case where color is not valid
        return false; // Assuming light color as default
      }
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminance < 150;
    },

    onWindowBlur() {
      if (this.currentlyEditingNoteId) {
        const note = this.notes.find(n => n.id === this.currentlyEditingNoteId);
        if (note && note.saveStatus === 'unsaved') {
          this.saveNote(note);
        }
      }
    },
    handleChange(note) {
      note.saveStatus = 'unsaved';
      if (this.saveTimeout) clearTimeout(this.saveTimeout);
      this.saveTimeout = setTimeout(() => {
        this.saveNote(note);
      }, 2000); // Adjust the timeout as needed.
    },

    exportNotes() {
      let exportedContent = '';
      this.notes.forEach(note => {
        exportedContent += `Note Title: ${note.name}\nNote Content:\n${note.content}\n\n`;
      });
      const blob = new Blob([exportedContent], {type: 'text/plain'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'notes_export.txt';
      link.click();
    },
    highlightText(text, query) {
      if (!query) return text;
      const highlighted = text.replace(
          new RegExp(query, 'gi'),
          (match) => `<span class="highlight">${match}</span>`
      );
      return highlighted;
    },
    exportNotesToPlainText() {
      let exportedText = '';
      this.notes.forEach(note => {
        // Convert HTML to plain text
        const plainTextContent = this.htmlToPlainText(note.content);
        exportedText += `Note Title: ${note.name}\nNote Content: ${plainTextContent}\n\n`;
      });

      // Trigger download of the exported text
      this.downloadTextFile(exportedText, 'ExportedNotes.txt');
    },

    htmlToPlainText(html) {
      const tempDivElement = document.createElement('div');
      tempDivElement.innerHTML = html;
      return tempDivElement.textContent || tempDivElement.innerText || '';
    },

    downloadTextFile(text, filename) {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    triggerNoteSave(note) {
      // Logic to trigger note saving process
      // For example, if you use `saveNote` method to save the note:
      this.saveNote(note);
    },
    selectNoteForEditing(note) {
      if (this.currentlyEditingNoteId && this.currentlyEditingNoteId !== note.id) {
        // Save the previously edited note
        const prevNote = this.notes.find(n => n.id === this.currentlyEditingNoteId);
        if (prevNote) {
          this.saveNote(prevNote);
        }
      }
      // Update the currently editing note ID
      this.currentlyEditingNoteId = note.id;
    },
     handleInput(note) {
        if (!note.isEdited) {
          note.isEdited = true; // Set the edited flag on the first change
          note.saveButtonClass = 'unsaved'; // Change button to red
          this.$forceUpdate(); // Ensure Vue updates the DOM if necessary
        }
      },

    encryptNote(content, passphrase) {
      console.log("Encrypting note with passphrase:", passphrase);
      const encrypted = CryptoJS.AES.encrypt(content, passphrase).toString();
      console.log("Encrypted content:", encrypted);
      return encrypted;
    },

    decryptNote(encryptedContent, passphrase) {
      console.log("Decrypting note with passphrase:", passphrase);
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedContent, passphrase);
        const decryptedContent = bytes.toString(CryptoJS.enc.Utf8);
        console.log("Decrypted content:", decryptedContent);
        if (!decryptedContent) {
          console.error('Decryption Error: Decryption returned empty content.');
          return null;
        }
        return decryptedContent;
      } catch (error) {
        console.error('Decryption Error:', error.message);
        return null;
      }
    },
    toggleEncryption(note) {
      let passphrase;

      if (note.isEncrypted) {
        passphrase = prompt('Enter your passphrase to decrypt the note:');
        if (passphrase) {
          const decryptedContent = this.decryptNote(note.content, passphrase);
          if (decryptedContent) {
            note.content = decryptedContent;
            note.isEncrypted = false;
          } else {
            alert('Decryption failed. Check your passphrase.');
            return;
          }
        }
      } else {
        passphrase = prompt('Enter a passphrase for encryption:');
        if (passphrase) {
          note.content = this.encryptNote(note.content, passphrase);
          note.isEncrypted = true;
        }
      }

      if (passphrase) {
        this.saveNote(note);
      }
    },

    async saveNote(note) {
      if (!note) return; // Early exit if the note object is not passed

      if (this.isInitialSave) {
        console.log("Skipping save during initial edit");
        return;
      }

      const noteData = {
        name: note.name,
        content: note.content, // Ensuring that only necessary fields are sent for update
        color: note.color,
        isEncrypted: note.isEncrypted,
        lastModified: serverTimestamp(), // Using Firebase's serverTimestamp for consistency
      };

      try {
        const noteRef = doc(db, 'notes', note.id); // Get a reference to the note document in Firestore
        await updateDoc(noteRef, noteData); // Update the document

        console.log("Note saved:", note.id); // Log the note ID that was saved
        if (note.saveStatus === 'unsaved') {
          note.saveStatus = 'saved';
          note.isEdited = false; // Reset the edited flag after save
          note.saveButtonClass = 'saved';
        }
      } catch (error) {
        console.error("Error saving note:", error); // More detailed error logging
      }
    },

// Call this method when a note starts being edited
    startEditing() {
      this.isInitialSave = false;
      //console.log("Started editing note:", note);
      //this.triggerAutoSave(note);
    },

    handleAuthStateChanged(user) {
      if (user) {
        this.user = user; // Set the user data
        this.loadNotes(user);
      } else {
        console.log("No user is currently logged in.");
        this.user = null; // Reset user to null if not logged in
      }
    },
    async loadNotes(user) {
      console.log("Loading notes for user:", user.uid);
      const notesQuery = query(
          collection(db, 'notes'),
          where('userId', '==', user.uid),
          orderBy('lastModified', 'desc')
      );

      onSnapshot(notesQuery, (snapshot) => {
        const updatedNotes = snapshot.docs.map(doc => {
          // Find the existing note in the current state
          const existingNote = this.notes.find(n => n.id === doc.id);

          return {
            id: doc.id,
            ...doc.data(),
            isEncrypted: doc.data().isEncrypted || false, // Add this line
            saveStatus: 'saved', // Set initial save status
            saveButtonClass: 'saved', // Set initial button class
            isVisible: existingNote ? existingNote.isVisible : false, // Preserve isVisible
          };
        });

        // Update notes state
        this.notes = updatedNotes;
        this.isInitialSave = false; // Reset the flag after notes are loaded
        //console.log("Notes loaded:", this.notes);

        // Adjust input width after DOM update
        this.notes.forEach(note => {
          this.$nextTick(() => {
            this.adjustInputWidth(note);
            this.updateEditorBackground(note);
          });
        });

      }, (error) => {
        console.error("Error fetching real-time notes:", error);
      });
    },

    toggleNoteVisibility(note) {
      note.isVisible = !note.isVisible;
    },

    async saveNoteName(note) {
      try {
        const noteRef = doc(db, 'notes', note.id);
        await updateDoc(noteRef, {
          name: note.name, // Update the note name in Firestore
        });

        console.log("Note name updated successfully");

        // Update the note name in the local state
        this.notes = this.notes.map(n => {
          if (n.id === note.id) {
            return {...n, name: note.name, content: note.content, color: note.color};
            // Note that isVisible is not overwritten
          }
          return n;
        });
      } catch (error) {
        console.error("Error updating note name:", error);
      }
    },
    getEditorConfig(noteColor) {
      return {
        // Define custom styles for the content inside CKEditor
        contentStyle: `body { background-color: ${noteColor}; }`
      };
    },
    updateEditorBackground(note) {
      this.$nextTick(() => {
        // Ensure the editor reference is available and the editor instance is already initialized
        const editorRef = this.$refs['editorInstance' + note.id];
        if (editorRef && editorRef.editorInstance) {
          const editorInstance = editorRef.editorInstance;

          // Check if the editor's document and body are available to manipulate
          if (editorInstance.document && editorInstance.document.getBody()) {
            // Only update the background color if it's different to avoid unnecessary changes
            const currentBgColor = editorInstance.document.getBody().getStyle('background-color');
            if (currentBgColor !== note.color) {
              editorInstance.document.getBody().setStyle('background-color', note.color);
            }
          }
        } else {
          console.log('Editor instance not found for note:', note.id);
        }
      });
    },

    initResize(event) {
      const noteElement = event.target.closest('.note');

      const startWidth = noteElement.offsetWidth;
      const startX = event.clientX;

      const doResize = (moveEvent) => {
        const currentX = moveEvent.clientX;
        const newWidth = startWidth + (currentX - startX);
        noteElement.style.width = `${newWidth}px`;
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', doResize);
        window.removeEventListener('mouseup', stopResize);
      };

      window.addEventListener('mousemove', doResize);
      window.addEventListener('mouseup', stopResize);
    },

    async addNote() {
      console.log("Adding note");
      if (!this.user || !this.user.uid) {
        console.error("User is not authenticated or user data is not available");
        return;
      }
      if (!this.newNoteName.trim()) {
        console.log("Please enter a note name.");
        return;
      }

      try {
        const newNote = {
          name: this.newNoteName, // Use the entered name
          color: this.noteColor, // Add the selected color
          userId: this.user.uid, // Assuming this.user is set from the auth state
          content: '<p>New Note</p>',
          createdAt: serverTimestamp(),
          lastModified: serverTimestamp(),
        };

        await addDoc(collection(db, 'notes'), newNote);
        console.log("Note added successfully");

        // Reset the new note name and possibly update local state
        this.newNoteName = '';
        this.noteColor = '#E5FFFD'; // Reset the color picker
        this.$nextTick(() => {
          this.updateEditorBackground(this.newlyCreatedNote);
        });
        // If you're maintaining a local list of notes, you might want to update it here
      } catch (error) {
        console.error("Error adding note:", error);
      }
    },
    updateNoteColor(note) {
      note.color = this.lightenColor(note.color, 30); // Adjust the percentage based on how light you want it
      this.updateEditorBackground(note);
      // Call saveNote to update the note in Firestore
      this.saveNote(note);
    },
    async deleteNote(noteId) {
      // Show confirmation dialog
      if (confirm("Are you sure you want to delete this note as it can't be recovered?")) {
        try {
          await deleteDoc(doc(db, 'notes', noteId));
          this.notes = this.notes.filter(note => note.id !== noteId);
          console.log("Note deleted successfully");
        } catch (error) {
          console.error("Error deleting note:", error);
        }
      } else {
        console.log("Deletion cancelled");
      }
    },

    debounce(func, wait, immediate) {
      let timeout;
      return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    adjustInputWidth(note) {
      this.$nextTick(() => {
        const refKey = 'noteInput' + note.id;
        const inputElements = this.$refs[refKey];

        if (inputElements) {
          let inputElement = Array.isArray(inputElements) ? inputElements[0] : inputElements;

          // Make sure inputElement is not undefined before proceeding
          if (inputElement) {
            const tempSpan = document.createElement('span');
            tempSpan.style.display = 'inline-block';
            tempSpan.style.visibility = 'hidden';
            tempSpan.style.fontWeight = 'bold';
            tempSpan.style.fontSize = '1.2em';
            tempSpan.textContent = note.name || ' ';
            document.body.appendChild(tempSpan);

            const textWidth = tempSpan.offsetWidth;
            document.body.removeChild(tempSpan);

            inputElement.style.width = `${textWidth + 20}px`;
          }
        }
      });
    },

    saveFontPreference() {
      localStorage.setItem('selectedFont', this.localSelectedFont);
    }
  },

  created() {
    this.handleInput = this.debounce(this.handleInput, 1000);
  },

  computed: {
    noteInputWidths() {
      const widths = {};
      this.notes.forEach(note => {
        const tempSpan = document.createElement('span');
        tempSpan.style.display = 'inline-block';
        tempSpan.style.visibility = 'hidden';
        tempSpan.style.fontWeight = 'bold';
        tempSpan.style.fontSize = '1.2em';
        tempSpan.textContent = note.name || ' ';
        document.body.appendChild(tempSpan);

        // Add a buffer to the width calculation
        const buffer = 20; // Adjust this value if needed
        widths[note.id] = (tempSpan.offsetWidth + buffer) + 'px';
        document.body.removeChild(tempSpan);
      });
      return widths;
    },
    inputTextColor() {
      console.log('Current color:', this.noteColor);
      return this.isColorDark(this.noteColor) ? 'white' : 'black';
    },
    filteredNotes() {
      return this.notes.filter(note => {
        const name = note.name || ""; // Ensures name is a string
        const content = note.content || ""; // Ensures content is a string

        return name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            content.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {
    console.log("mounted hook executed");
    window.addEventListener('blur', this.onWindowBlur);

    onAuthStateChanged(auth, this.handleAuthStateChanged.bind(this));

    // Initialize Isotope layout after Vue has updated the DOM
    this.$nextTick(() => {
      if (this.$refs.notesContainer) {
        this.isotope = new Isotope(this.$refs.notesContainer, {
          itemSelector: '.note',
          percentPosition: true,
          masonry: {
            columnWidth: '.note'
          }
        });
      }
    });

    // Call cloneToolbar for each note after mounting to ensure the toolbar is cloned at the bottom
    this.notes.forEach(note => {
      this.cloneToolbar(note.id);
    });

    this.notes.forEach(note => {
      note.saveStatus = 'saved';
      note.saveButtonClass = 'saved';
      this.notes.forEach(note => {
        this.adjustInputWidth(note); // Adjust width on mount for each note
      });
    });

    // Load saved font preference from local storage
    const savedFont = localStorage.getItem('selectedFont');
    if (savedFont) {
      this.localSelectedFont = savedFont; // Update local data property
    }
  },

  beforeUmount() {
    // Remove the event listener
    window.removeEventListener('blur', this.onWindowBlur);
  },
}

</script>

<style>
.header-container {
  position: fixed; /* Fix the header at the top */
  top: 0;
  width: 100%;
  display: flex;
  z-index: 20; /* Ensure the header stays above everything else */
  background-color: white; /* Ensure it's visually distinct */
  height: 40px; /* Set the height of the header */
  box-shadow: 0px 4px 2px -2px gray; /* Add a shadow to distinguish the header */
}

.header-image {
  max-width: 100%;
  height: auto;
  max-height: 40px; /* The image height matches the header */
  object-fit: contain;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2vh;
}

.top-section {
  display: flex;
  width: 100%;
  /* Removed fixed height to allow dynamic adjustment */
}

.parent-div * {
  font-family: inherit;
}

.notes-container {
  position: relative; /* Use relative if sticky causes overlap issues */
  top: 0px; /* Adjust as needed, or remove if using position: relative */
  /* Rest of your styles */
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Spacing between notes */
}
.note-being-edited {
  position: sticky;
  top: 0px; /* Adjust this to ensure it stays below the header */
  z-index: 10; /* To ensure it stays on top of other content */
  background-color: inherit; /* Keep the background color intact */
}

.export-button {
  /* Styling for the export button */
  background-color: darkgreen; /* Green background */
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.note {
  flex: 1;
  min-width: 550px; /* Minimum width of the note */
  max-width: calc(100% - 16px); /* Maximum width */
  background-color: lightgreen;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative; /* For absolute positioning of the resize handle */
  resize: horizontal; /* Enables horizontal resizing */
  overflow: auto; /* Ensure that the note can scroll when content is long */
  white-space: normal;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  /* Adjust the note width for smaller screens */
  .note {

    width: calc(100% - 5%);
    font-size: 14px; /* Reduce font size to fit more content on screen */
    padding: 10px; /* Reduce padding to fit smaller screens */
  }
}

.resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background: #ccc;
  cursor: ew-resize;
}

.input-field, .button {
  font-size: 14px;
  height: 36px; /* Example height, adjust as needed */
  padding: 0 10px; /* Padding inside */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid #000; /* Consistent border */
  background-color: #f5f5f5; /* Background color */
  color: darkgrey; /* Text color */
  margin-right: 10px; /* Spacing between elements */
  //outline: none; /* Removes outline on focus */
}

/* Specific style for input fields */
.input-field {
  /* width: calc(100% - 24px);  Full width taking into account padding and margin */
  flex-grow: 1; /* Allows the input to grow and fill available space */
  margin-right: 10px; /* Space between the input and the color picker */
  width: 400px
  /* Common styles for input fields */
}

/* Specific style for buttons */
.button {
  cursor: pointer; /* Changes cursor to pointer on hover */
  background-color: #f5f5f5; /* Slightly different background for buttons */
  border: 2px solid black;
  border-radius: 4px; /* Rounded corners */
}

.notes-controls {

  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 5px; /* Space between elements */
}

/* General style for input fields */
.input-field {
  color: black;
}

.note-input {
  margin-left: 20px;
  margin-right: 0px;
  width: 350px;
  flex-grow: 1; /* Allows the input to grow and fill available space */
}

/* Specific style for the search input field */
.search-input {
  margin-left: 20px;
  margin-right: 10px; /* Adjust the right margin */
  flex-grow: 1; /* Allows the input to grow and fill available space */
}

/* Specific style for delete note button */
.delete-note-button {
  margin-left: 20px;
  margin-right: 10px;
}

.red-dot, .green-dot {
  flex-shrink: 0;
  fles-grow: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid black; /* For visibility */
  position: relative; /* Adjust as needed */
  z-index: 10; /* Higher z-index to bring it to the front */
}

.note-actions {
  display: flex;

  margin-top: 10px; /* Adjust as needed */
}

.save-note-button {
  background-color: darkred;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.delete-note-button {
  background-color: darkred;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.save-note-button.saved {
  background-color: darkgreen;
}

.hide-note-button {
  background-color: darkblue;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  width: 90px;
}

.encrypt-note-button {
  background-color: yellow;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  width: 90px;
}

.add-note-button {
  flex-shrink: 0; /* Prevents the button from shrinking */
  width: 100px; /* Adjust the width of the button */
  height: 36px;
  margin-right: 10px;
  cursor: pointer; /* Changes cursor to pointer on hover */
  background-color: #f5f5f5; /* Slightly different background for buttons */
  border: 1px solid black;
  border-radius: 4px; /* Rounded corners */
}

.color-picker {
  flex-shrink: 0;
  padding: 0;
  border: none; /* Removes default border of color input */
  cursor: pointer;
}

/* Adjusts the appearance of the color picker to match other inputs */
.color-picker::-webkit-color-swatch-wrapper {
  border: 1px solid #000000; /* Example: black border, 2 pixels thick */
  cursor: pointer;
  border-radius: 4px; /* Optional: rounded corners */
  padding: 2px; /* Optional: inner padding */
}

.color-picker::-webkit-color-swatch {
  border: none;
}

.color-picker-wrapper {
  display: inline-block;
  position: relative;
}

.color-picker {
  height: 36px; /* Match height with other inputs */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid #cccccc; /* Consistent border */
  margin-top: 0px;
  padding: 0px;
  background-color: #f5f5f5; /* Background color */
  cursor: pointer; /* Cursor pointer */
}

.color-picker-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  color: #000;
  pointer-events: none;
}

.note-header {
  position: sticky;
  z-index: 15;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  gap: 5px; /* Adds space between elements */
  margin-bottom: 10px;
}

.note-header span {
  vertical-align: initial; /* Align dot with text */
}

.note-name-input {
  text-align: left; /* Align text to left */
  flex-grow: 1; /* Do not allow it to grow beyond its content width*/
  flex-shrink: 1; /* Allow it to shrink if necessary */
  flex-basis: auto; /* Auto basis based on content */

  font-size: 1.1em;
  font-weight: bold;
  border: 2px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.note-content {
  /* Style as needed, the background color is inherited from .note */
}

.color-picker-wrapper {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
}

.color-picker {
  flex-shrink: 0;
  opacity: 0.7; /* Make the color picker slightly transparent to show the text */
}

.color-picker-text {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  color: #000; /* Adjust text color as needed */
  margin-left: 10px; /* Spacing between the color picker and the text */
  pointer-events: none; /* Makes the text non-interactive */
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.image-style-align-left {
  float: left;
  margin-right: 1em;
}

.image-style-align-center {
  display: block;
  margin: auto;
}

.image-style-align-right {
  float: right;
  margin-left: 1em;
}
.bottom-toolbar-container {
  margin-top: 10px;
  position: relative; /* Ensure it's within the flow of the note */
  z-index: 5; /* Ensure it's above the note content */
}
.ck-toolbar {
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  z-index: 20; /* Higher than other elements to make sure it stays on top */
}
</style>
