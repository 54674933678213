<template>
  <div class="flashcard">
    <!-- Header Section -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image" />
    </div>

    <!-- Item Section (Editable) -->
    <div class="card-section">
      <input v-model="editedCard.Item" placeholder="Enter Item" />
    </div>

    <!-- Description Section (Editable) -->
    <div class="card-section">
      <textarea v-model="editedCard.Description" placeholder="Enter Description"></textarea>
    </div>

    <!-- Details Section (Editable) -->
    <div class="card-section">
      <textarea v-model="editedCard.Details" placeholder="Enter Details"></textarea>
    </div>

    <!-- Image Section -->
    <div class="card-section">
      <img :src="editedCard.Image" @click="changeImage" alt="Card image" />
      <input type="file" @change="onImageUpload" style="display: none;" ref="fileInput" />
    </div>

    <!-- Category and Subcategory Dropdowns with Search -->
    <div class="category-selection-row">
      <!-- Category Dropdown with Search -->
      <div class="dropdown-wrapper">
        <button @click="toggleCategoryDropdown" class="select-category-button">{{ selectedCategory || 'Category' }}</button>
        <div v-if="showingCategory" class="dropdown-content">
          <input type="text" v-model="categorySearch" placeholder="Search Category" class="search-input" />
          <select v-model="selectedCategory" @change="selectCategory">
            <option value="" disabled>Select Category</option>
            <option v-for="category in filteredCategories" :key="category" :value="category">{{ category }}</option>
          </select>
        </div>
      </div>

      <!-- Subcategory Dropdown with Search -->
      <div class="dropdown-wrapper">
        <button @click="toggleSubcategoryDropdown" class="select-category-button" :disabled="!selectedCategory">{{ selectedSubcategory || 'Subcategory' }}</button>
        <div v-if="showingSubcategory" class="dropdown-content">
          <input type="text" v-model="subcategorySearch" placeholder="Search Subcategory" class="search-input" />
          <select v-model="selectedSubcategory" @change="selectSubcategory">
            <option value="" disabled>Select Subcategory</option>
            <option v-for="subcategory in filteredSubcategories" :key="subcategory" :value="subcategory">{{ subcategory }}</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Next, Previous, Delete, New, and Update buttons -->
    <div class="controls">
      <button @click="previousCard" class="control-button orange">Previous</button>
      <button @click="nextCard" class="control-button orange">Next</button>
    </div>
    <div class="controls">
      <button @click="deleteCategory" class="control-button red">Delete Entire Category</button>
      <button @click="deleteSubcategory" class="control-button red">Delete Entire Subcat</button>
    </div>
    <div class="controls">
      <button @click="createNewCard" class="control-button green">New</button>
      <button @click="updateCard" class="control-button purple">Update</button>
    </div>
  </div>

</template>

<script>
// Importing necessary Firebase functions
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      editedCard: {}, // The card being edited
      categories: [], // Categories loaded dynamically
      subcategories: [], // Subcategories loaded dynamically
      categorySearch: '', // Search field for category
      subcategorySearch: '', // Search field for subcategory
      currentCard: { Item: '', Description: '', Details: '', Image: '' },
      selectedCategory: '',
      selectedSubcategory: '',
      showingCategory: false,
      showingSubcategory: false,
    };
  },
  computed: {
    // Alphabetically sorted and filtered categories based on the search input
    filteredCategories() {
      return this.categories
        .filter(category => category.toLowerCase().includes(this.categorySearch.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
    },
    // Alphabetically sorted and filtered subcategories based on the search input
    filteredSubcategories() {
      return this.subcategories
        .filter(subcategory => subcategory.toLowerCase().includes(this.subcategorySearch.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
    }
  },
  methods: {
    // Load categories dynamically
    async loadCategories() {
      try {
        const cardsCollection = collection(db, 'cards');
        const snapshot = await getDocs(cardsCollection);
        const categoriesSet = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.Category) {
            categoriesSet.add(data.Category);
          }
        });
        this.categories = Array.from(categoriesSet);
        console.log('Categories loaded:', this.categories);
      } catch (error) {
        console.error('Error loading categories:', error);
      }
    },

    // Load cards by category and subcategory
    async loadCards(category, subcategory) {
      try {
        const cardsCollection = collection(db, 'cards');
        const q = query(cardsCollection, where('Category', '==', category), where('Subcategory', '==', subcategory));
        const snapshot = await getDocs(q);
        this.cards = snapshot.docs.map((doc) => ({
          id: doc.id, // Include the Firestore document ID here
          ...doc.data(),
        }));
        if (this.cards.length > 0) {
          this.currentCardIndex = 0;
          this.editedCard = { ...this.cards[0] };
          console.log('Cards loaded for category:', category, 'and subcategory:', subcategory);
        } else {
          this.editedCard = null;
          console.log('No cards found for category:', category, 'and subcategory:', subcategory);
        }
      } catch (error) {
        console.error('Error loading cards:', error);
      }
    },

    // Load subcategories dynamically based on selected category
    async loadSubcategories(category) {
      try {
        const cardsCollection = collection(db, 'cards');
        const q = query(cardsCollection, where('Category', '==', category));
        const snapshot = await getDocs(q);
        const subcategoriesSet = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.Subcategory) {
            subcategoriesSet.add(data.Subcategory);
          }
        });
        this.subcategories = Array.from(subcategoriesSet);
        console.log('Subcategories loaded for category:', category, this.subcategories);
      } catch (error) {
        console.error('Error loading subcategories:', error);
      }
    },

    // Toggle category dropdown visibility
    toggleCategoryDropdown() {
      this.showingCategory = !this.showingCategory;
      this.showingSubcategory = false;
    },

    // Toggle subcategory dropdown visibility
    toggleSubcategoryDropdown() {
      this.showingSubcategory = !this.showingSubcategory;
      this.showingCategory = false;
    },

    // Handle category selection
    async selectCategory() {
      this.selectedSubcategory = '';
      this.showingCategory = false;
      console.log('Selected category:', this.selectedCategory);
      await this.loadSubcategories(this.selectedCategory); // Load subcategories when category is selected
    },

    // Handle subcategory selection and load cards
    selectSubcategory(event) {
      this.selectedSubcategory = event.target.value || this.subcategories[0];
      this.showingSubcategory = false;
      console.log('Selected subcategory:', this.selectedSubcategory);
      this.loadCards(this.selectedCategory, this.selectedSubcategory);
    },

    // Navigate to the previous card
    previousCard() {
      if (this.currentCardIndex === 0) {
        this.currentCardIndex = this.cards.length - 1;
      } else {
        this.currentCardIndex -= 1;
      }
      this.editedCard = { ...this.cards[this.currentCardIndex] };
    },

    // Navigate to the next card
    nextCard() {
      this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
      this.editedCard = { ...this.cards[this.currentCardIndex] };
    },

    // Image upload handler
    onImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.backImage = URL.createObjectURL(file);
        this.editedCard.Image = this.backImage; // Update the image URL
      }
    },

    // Change image method
    changeImage() {
      this.$refs.fileInput.click(); // Trigger file input
    },

    // Delete the entire category
    async deleteCategory() {
      if (confirm('Are you sure you want to delete the entire category?')) {
        const cardsToDelete = this.cards.filter(card => card.Category === this.selectedCategory);
        for (const card of cardsToDelete) {
          await deleteDoc(doc(db, 'cards', card.id));
        }
        alert('Category deleted');
        this.loadCategories(); // Refresh categories
      }
    },

    // Delete the entire subcategory
    async deleteSubcategory() {
      if (confirm('Are you sure you want to delete the entire subcategory?')) {
        const cardsToDelete = this.cards.filter(card => card.Subcategory === this.selectedSubcategory);
        for (const card of cardsToDelete) {
          await deleteDoc(doc(db, 'cards', card.id));
        }
        alert('Subcategory deleted');
        this.loadSubcategories(this.selectedCategory); // Refresh subcategories
      }
    },

    // Create a new card
    async createNewCard() {
      const newCard = { ...this.editedCard };
      await addDoc(collection(db, 'cards'), newCard);
      alert('New card created');
      this.loadCards(this.selectedCategory, this.selectedSubcategory); // Reload cards
    },

    // Update the current card
    async updateCard() {
      const cardId = this.editedCard.id;
      if (!cardId) return;

      await updateDoc(doc(db, 'cards', cardId), this.editedCard);
      alert('Card updated');
    },
  },
  async mounted() {
    await this.loadCategories();
    if (this.selectedCategory && this.selectedSubcategory) {
      await this.loadCards(this.selectedCategory, this.selectedSubcategory);
    }
  },
};
</script>


<style scoped>
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.flashcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
}

.card-section {
  width: 100%; /* Full width of parent */
  max-width: 350px; /* Maximum width for each card section */
  min-height: 40px; /* Minimum height */
  margin: 3px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.6s ease-in-out;
  border: 2px solid #000; /* Consistent black border */
  padding: 5px;
  position: relative;
  perspective: 1000px; /* 3D perspective for flip effect */
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff; /* Ensure white background */
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back when flipped */
  transition: transform 0.6s ease-in-out;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;      /* Vertically center the content */
  text-align: center;       /* Ensure text is centered */
  padding: 10px;
  box-sizing: border-box; /* Ensure padding doesn’t affect size */
}

/* Card content (default, no scroll) */
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

/* Scrollable content for description and details */
.card-content.scrollable {
  justify-content: flex-start; /* Align text to start (top) */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100%;
  padding: 0; /* Remove padding to avoid extra space */
  text-align: center;
  word-wrap: break-word;
  box-sizing: border-box;
}

.card-content.scrollable p {
  margin: 0; /* Ensure no margin on paragraph */
  padding: 0 10px; /* Add horizontal padding only */
}

.card-front {
  background-color: #f9f9f9; /* Light background for front */
}

.card-back {
  background-color: #e0e0e0; /* Slightly darker background for back */
  transform: rotateY(180deg); /* Default flipped state for the back */
}

.flipped .card-front {
  transform: rotateY(180deg); /* Rotate front when flipped */
}

.flipped .card-back {
  transform: rotateY(0deg); /* Rotate back to show when flipped */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  height: auto;
}

.popup-content {
  text-align: center;
}

.image-adjustment-container {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: move;
  transition: background-size 0.2s ease;
}

.split-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: red;
}

.split-line:nth-child(1) {
  top: 40px;
}

.split-line:nth-child(2) {
  top: 100px;
}

.split-line:nth-child(3) {
  top: 240px;
}

button {
  margin-top: 0px;
  padding: 8px 12px;
  cursor: pointer;
}

.back-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 200% 200%;
}

.flipped-item .card-back {
  background-position: 0% 0%;
}

.flipped-description .card-back {
  background-position: 100% 0%;
}

.flipped-details .card-back {
  background-position: 0% 100%;
}

.flipped-image .card-back {
  background-position: 100% 100%;
}

.description-section {
  height: 60px;
  width: 350px;
}

.description-section p {
  font-size: 18px;
}

.details-section {
  height: 140px;
  width: 350px;
}

.details-section p {
  font-size: 14px;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.resizable-image {
  width: 100%;
  height: auto;
  max-height: 440px;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.grid-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: red;
  pointer-events: none;
}

.image-section {
  height: 200px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 10px;
  background-color: white;
}

.image-section img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.upload-back-image button {
  background-color: darkblue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-button {
  background-color: darkblue;
  color: white;
  padding: 5px;
  border-radius: 5px;
  background-color: rebeccapurple;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
}
.category-selection-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.select-category-button {
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  background-color: darkblue;
  color: white;
  border: none;
  cursor: pointer;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.controls,
.difficulty-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 340px;
  margin-top: 0px;
}

.control-button,
.select-category-button {
  width: 160px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: 5px 5px;
}

.control-button.dark-green {
  background-color: darkgreen;
  color: white;
}

.control-button.red {
  background-color: red;
  color: white;
}

.select-category-button {
  background-color: darkblue;
  color: white;
}

.control-button.purple {
  background-color: purple;
  color: white;
}

.control-button.dark-red {
  background-color: darkred;
  color: white;
}

.control-button.green {
  background-color: green;
  color: white;
}

.control-button.orange {
  background-color: darkorange;
  color: black;
}

.custom-select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  margin-top: 0px;
}

.dropdown select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  bottom: 100%; /* This makes the dropdown pop upwards */
  left: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.dropdown-content select {
  width: 100%;
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.dropdown-content select:focus {
  outline: none;
}

.select-category-button {
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: darkblue;
  color: white;
}


.custom-select:focus {
  outline: none;
  border-color: darkblue;
}

select option {
  background-color: white;
  color: black;
}

.category-selection-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0;
}
</style>