import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import storage module

const firebaseConfig = {
    apiKey: "AIzaSyD-oMicEpZ6q0YJToTk1i6FGL49oyOTtD8",
    authDomain: "platinum-scout-394120.firebaseapp.com",
    projectId: "platinum-scout-394120",
    storageBucket: "platinum-scout-394120.appspot.com",
    messagingSenderId: "235896629538",
    appId: "1:235896629538:android:20fc1e47dc8a79bbe11d7b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Authentication, and Storage
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage using modular SDK

// Export db, auth, and storage
export { db, storage, auth };

