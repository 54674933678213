<template>
  <div class="auth-container">
    <h2>Sign In</h2>
    <form @submit.prevent="signIn">
      <input type="email" v-model="email" placeholder="Email">
      <input type="password" v-model="password" placeholder="Password" autocomplete="current-password">
      <button type="submit">Sign In</button>
    </form>

    <h2>Register</h2>
    <form @submit.prevent="register">
      <input type="email" v-model="regEmail" placeholder="Email">
      <input type="password" v-model="regPassword" placeholder="Password">
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import { auth, db } from '@/firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      email: '',
      password: '',
      regEmail: '',
      regPassword: '',
      username: ''
    };
  },
  methods: {
    async signIn() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        await this.updateUserRole(user);

        if (this.$toast) {
          this.$toast.success("Sign-in successful!");
        }
      } catch (error) {
        console.error('Sign-in error:', error.message);
        if (this.$toast) {
          this.$toast.error("Sign-in error: " + error.message);
        }
      }
    },
    async register() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.regEmail, this.regPassword);
        const user = userCredential.user;
        await this.createUserRecord(user);

        if (this.$toast) {
          this.$toast.success("Registration successful!");
          await this.signIn();
        }
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          console.error('Registration error: Email already in use.');
          this.$toast.error("Registration error: Email already in use.");
        } else {
          console.error('Registration error:', error.message);
          this.$toast.error("Registration error: " + error.message);
        }
      }
    },
    async createUserRecord(user) {
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        username: this.username,
        email: user.email,
        role: 'user'
      });
      localStorage.setItem('user', JSON.stringify({email: user.email, role: 'user'}));
    },
    async updateUserRole(user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("Fetched user data:", userData);
          const userRole = userData.role;
          localStorage.setItem('user', JSON.stringify({email: user.email, role: userRole}));
        } else {
          console.log(`No document found for user ID: ${user.uid}`);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
